<template>
 <div>
  <sdPageHeader title="Product configuratie">
  </sdPageHeader>
  <Main v-if="errorProduct">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/product')">
       Naar producten
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-if="loadingProduct">
   <sdCards>
    <a-skeleton active/>
   </sdCards>
  </Main>
  <Main v-else-if="!loadingProduct">
   <sdModal :onCancel="handleFileUploadCancel" :visible="showFileUploadRef" title="Product bestanden" type="primary">
    <ModalMain>
     <div class="create-account-form">
      <a-form :model="fileList" layout="vertical" @finish="handleFileUpload">
       <a-row :gutter="30">
        <a-col
         :xs="24"
        >
         <div class="clearfix">
          <a-upload-dragger :before-upload="beforeUpload" :file-list="fileList" :remove="handleRemove"
                            multiple="false">
           <a-button :disabled="fileList.length === 1">
            <upload-outlined></upload-outlined>
            Selecteer bestand
           </a-button>
          </a-upload-dragger>
         </div>
        </a-col>
        <a-col
         :xs="24"
        >
         <a-button
          :disabled="fileList.length < 0"
          :loading="uploading"
          html-type="submit"
          size="large"
          style="margin-top: 16px"
          type="primary"
         >
          {{ uploading ? 'Uploading' : 'Start Upload' }}
         </a-button>
        </a-col>
       </a-row>
      </a-form>
     </div>
    </ModalMain>
   </sdModal>
   <TabBasic
    v-if="form.type !== null && form.type === 'ENERGY'"
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
    @tabClick="handleTabClickEnergy"
   >
    <Child :key="1">
     <template #tab>
       <span>
         <sdFeatherIcons size="18" type="package"/>
         Overview
       </span>
     </template>
     <a-row :gutter="15" type="flex">
      <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
       <Upload style="flex: 1 1 auto;">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
           <a-skeleton v-if="loadingProduct" active/>
           <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct1">
            <a-row :gutter="30">
             <a-col :sm="24" :xs="24" class="mb-25">
              <figure class="photo-upload align-center-v">
               <img
                v-if="form.imageFileURL && form.imageFileURL !== ''"
                :src="form.imageFileURL"
                alt=""
                style="max-width: 120px"
               />
               <img
                v-else
                :src="require('@/static/img/avatar/profileImage.png')"
                alt=""
                style="max-width: 120px"
               />
               <figcaption>
                <a-upload
                 :action="`${fileUrl}products/${form.id}/file`"
                 :disabled="!$can('update', 'product')"
                 :headers="fileUploadHeaders"
                 :multiple="false"
                 name="file"
                 @change="handleUpload">
                 <a class="btn-upload" to="#">
                  <sdFeatherIcons size="16" type="camera"/>
                 </a>
                </a-upload>
               </figcaption>
              </figure>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                           name="name">
               <a-input v-model:value="form.name" :disabled="!$can('update', 'product')" placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Leverancier"
                           name="supplierID">
               <a-select v-model:value="form.supplierID" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in suppliers" :key="index"
                                 :value="item.id" name="supplierID">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                           name="type">
               <a-select v-model:value="form.type" :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in productTypes" :key="index"
                                 :value="item.key" name="type">
                 {{ item.value }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Energie type"
                           name="energyType">
               <a-select v-model:value="form.energyType" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in energyTypeOptions" :key="index"
                                 :value="item.value" name="energyType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Retentie"
                           name="retentionType">
               <a-select v-model:value="form.retentionType" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in retentionTypeOptions" :key="index"
                                 :value="item.value" name="retentionType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Looptijd type"
                           name="contractDurationType">
               <a-select v-model:value="form.contractDurationType"
                         :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select" size="large"
                         @change="form.contractDurationMonths = null;form.contractDurationDate = '';">
                <a-select-option v-for="(item,index) in contractDurationType"
                                 :key="index"
                                 :value="item.value" name="contractDurationType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.contractDurationType === 'MONTHS'"
                    :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Contractduur"
                           name="contractDurationMonths">
               <a-input v-model:value="form.contractDurationMonths"
                        :addon-after="!form.contractDurationMonths || form.contractDurationMonths === '0' || form.contractDurationMonths === '1'  ? 'maand' : 'maanden'"
                        :disabled="!$can('update', 'product')"
                        placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col v-if="form.contractDurationType === 'DATE'" :sm="12"
                    :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Vaste einddatum"
                           name="contractDurationDate">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.contractDurationDate" :disabled="!$can('update', 'product')"
                               :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col v-if="form.energyType === 'DUAL'" :sm="12" :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label="Beschikbaar als Stroom Single Fuel"
                           name="energyAllowSingleFuelElectricity">
               <a-select v-model:value="form.energyAllowSingleFuelElectricity"
                         :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions"
                                 :key="index"
                                 :value="item.value" name="energyAllowSingleFuelElectricity">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.energyType === 'DUAL'" :sm="12" :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label="Beschikbaar als Gas Single Fuel"
                           name="energyAllowSingleFuelGas">
               <a-select v-model:value="form.energyAllowSingleFuelGas"
                         :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="energyAllowSingleFuelGas">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Zakelijk of Consument"
                           name="allowedCustomerType">
               <a-select v-model:value="form.allowedCustomerType" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in customerTypeOptions" :key="index"
                                 :value="item.value" name="allowedCustomerType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Is een machtiging?"
                           name="isProxy">
               <a-select v-model:value="form.isProxy" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="isProxy">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Tonen in offerte flow?"
                           name="hasEsignatureSigning">
               <a-select v-model:value="form.hasEsignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasEsignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label=" Tonen in overeenkomsten flow"
                           name="hasSignatureSigning">
               <a-select v-model:value="form.hasSignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasSignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van"
                           name="validFrom"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validFrom" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot"
                           name="validTill"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validTill" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
            </a-row>
            <a-row>
             <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
               <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 1 && loadingPatchProduct"
                         :loading="selectedUpdateButton === 1 && loadingPatchProduct" class="btn-signin"
                         html-type="submit" size="large"
                         type="primary"> Opslaan
               </a-button>
              </div>
             </a-col>
            </a-row>
           </a-form>
          </sdCards>
         </BasicFormWrapper>
        </div>
       </Upload>
      </a-col>
      <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
       <sdCards :style="!$can('update', 'product')?'flex: 1 1 auto;':''" title="Informatie">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <InfoWrapper>
           <div class="info-title">ID</div>
           <div class="info-text">{{ form.id }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Status</div>
           <div class="info-text"><span :style="`color: white;background-color: ${form.active?'#0CAB7C':'#E30D0F'}`"
                                        className="status-text">{{
             form.active ? 'Actief' : 'Inactief'
            }}</span></div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Revisie ID</div>
           <div class="info-text">{{ form.parentID ? form.parentID : 'Geen revisie beschikbaar' }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum aangemaakt</div>
           <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum geupdate</div>
           <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
         </a-col>
        </a-row>
       </sdCards>
       <sdCards v-if="$can('update', 'product')" style="flex: 1 1 auto;" title="Acties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchProduct && selectedUpdateButton === 12"
                     :loading="loadingPatchProduct && selectedUpdateButton ===12"
                     :type="form.active? 'danger' : 'primary'"
                     class="btn-signin w-100"
                     size="large"
                     @click.prevent="activateProduct">
            <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
            <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
            {{ form.active ? 'Deactiveren' : 'Activeren' }}
           </a-button>
          </div>
         </a-col>
         <a-col v-if="!form.isProxy && form.type !== null && form.type === 'ENERGY'" :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingSyncTariffForProduct" :loading="loadingSyncTariffForProduct"
                     class="btn-signin w-100"
                     size="large"
                     type="primary"
                     @click.prevent="syncTariff">
            <sdFeatherIcons class="mr-2" size="16" type="refresh-cw"/>
            Tarieven syncen
           </a-button>
          </div>
         </a-col>
        </a-row>
       </sdCards>
      </a-col>
     </a-row>
     <!--     <div v-if="form.type !== null && form.type === 'ENERGY'" class="user-info-form">-->
     <!--      <BasicFormWrapper class="mb-25">-->
     <!--       <sdCards title="Ecedo configuratie">-->
     <!--        <a-skeleton v-if="loadingProduct" active/>-->
     <!--        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">-->
     <!--         <a-row :gutter="30">-->
     <!--          <a-col :sm="8" :xs="24" class="mb-25">-->
     <!--           <a-form-item label="Elektriciteit PV"-->
     <!--                        name="expiryDays">-->
     <!--            <a-input :disabled="!$can('update', 'product')"-->
     <!--                     placeholder=""-->
     <!--                     type="text"/>-->
     <!--           </a-form-item>-->
     <!--          </a-col>-->
     <!--          <a-col :sm="8" :xs="24" class="mb-25">-->
     <!--           <a-form-item label="Gas PV"-->
     <!--                        name="expiryDays">-->
     <!--            <a-input :disabled="!$can('update', 'product')"-->
     <!--                     placeholder=""-->
     <!--                     type="text"/>-->
     <!--           </a-form-item>-->
     <!--          </a-col>-->
     <!--          <a-col :sm="8" :xs="24" class="mb-25">-->
     <!--           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Elektriciteit Reference"-->
     <!--                        name="expiryDays">-->
     <!--            <a-input :disabled="!$can('update', 'product')"-->
     <!--                     placeholder=""-->
     <!--                     type="text"-->
     <!--                     value="MC-E-1M-VAR-2"/>-->
     <!--           </a-form-item>-->
     <!--          </a-col>-->
     <!--          <a-col :sm="8" :xs="24" class="mb-25">-->
     <!--           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Gas PV"-->
     <!--                        name="expiryDays">-->
     <!--            <a-input :disabled="!$can('update', 'product')"-->
     <!--                     placeholder=""-->
     <!--                     type="text"-->
     <!--                     value="MC-G-1M-VAR-2"/>-->
     <!--           </a-form-item>-->
     <!--          </a-col>-->
     <!--          <a-col :sm="8" :xs="24" class="mb-25">-->
     <!--           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"-->
     <!--                        label="Elektriciteit Meelift Reference"-->
     <!--                        name="expiryDays">-->
     <!--            <a-input :disabled="!$can('update', 'product')"-->
     <!--                     placeholder=""-->
     <!--                     type="text"-->
     <!--                     value="E-Standaard_1"/>-->
     <!--           </a-form-item>-->
     <!--          </a-col>-->
     <!--          <a-col :sm="8" :xs="24" class="mb-25">-->
     <!--           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Gas Meelift Reference"-->
     <!--                        name="expiryDays">-->
     <!--            <a-input :disabled="!$can('update', 'product')"-->
     <!--                     placeholder=""-->
     <!--                     type="text"-->
     <!--                     value="G-Standaard_1"/>-->
     <!--           </a-form-item>-->
     <!--          </a-col>-->
     <!--         </a-row>-->
     <!--         <a-row>-->
     <!--          <a-col :sm="12" :xs="24">-->
     <!--           <div class="sDash_form-action">-->
     <!--            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"-->
     <!--                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"-->
     <!--                      html-type="submit" size="large"-->
     <!--                      type="primary"> Opslaan-->
     <!--            </a-button>-->
     <!--           </div>-->
     <!--          </a-col>-->
     <!--         </a-row>-->
     <!--        </a-form>-->
     <!--       </sdCards>-->
     <!--      </BasicFormWrapper>-->
     <!--     </div>-->
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Product omschrijving">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">
         <a-row :gutter="30">
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Extra informatie">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.description"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Unique Selling Point">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.uspText"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Eigenschappen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct3">
         <a-row :gutter="30">
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Soho"
                        name="isSoho">
            <a-select v-model:value="form.isSoho" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="isSoho">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zichtbaarheid op basis van BAG resultaat"
                        name="energyBagLimitationType">
            <a-select v-model:value="form.energyBagLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in bagLimitationOptions" :key="index"
                              :value="item.value" name="energyBagLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Verloop type voor offertes"
                        name="expiryType">
            <a-select v-model:value="form.expiryType" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in productExpiryTypeOptions" :key="index"
                              :value="item.value" name="expiryType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.expiryType === 'FIXEDDAYS'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte verloopdatum"
                        name="expiryDays">
            <a-input v-model:value="form.expiryDays"
                     :addon-after="!form.expiryDays || form.expiryDays === '0' || form.expiryDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Startdatum verplicht?"
                        name="energyHasStartDate">
            <a-select v-model:value="form.energyHasStartDate" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasStartDate">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Minimale leeftijd"
                        name="minAge">
            <a-input v-model:value="form.minAge" :disabled="!$can('update', 'product')" addon-after="jaar"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Maximale leeftijd"
                        name="maxAge">
            <a-input v-model:value="form.maxAge" :disabled="!$can('update', 'product')" addon-after="jaar"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Uitgebreide tarief condities"
                        name="hasExtendedEnergyTariffLimitation">
            <a-select v-model:value="form.hasExtendedEnergyTariffLimitation"
                      :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions"
                              :key="index"
                              :value="item.value" name="hasExtendedEnergyTariffLimitation">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender overrulen"
                        name="overrideSender">
            <a-select v-model:value="form.overrideSender" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.overrideSenderID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="overrideSender">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.overrideSender && form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24"
                 class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender"
                        name="overrideSenderID">
            <a-select v-model:value="form.overrideSenderID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in senders" :key="index"
                              :value="item.id" name="senderID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 3 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 3 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Templates">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct4">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Pdf Template"
                        name="pdfTemplateID">
            <a-select v-model:value="form.pdfTemplateID" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.PDF" :key="index"
                              :value="item.id" name="pdfTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email offerte template"
                        name="offerEmailTemplateID">
            <a-select v-model:value="form.offerEmailTemplateID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="offerEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bevestigingsmail versturen"
                        name="hasConfirmationEmail">
            <a-select v-model:value="form.hasConfirmationEmail" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.confirmationEmailTemplateID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasConfirmationEmail" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email bevestiging template"
                        name="confirmationEmailTemplateID">
            <a-select v-model:value="form.confirmationEmailTemplateID"
                      :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="confirmationEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Scripts">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct12">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label=""
                        name="hasScript">
            <a-select v-model:value="form.hasScript" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.scriptID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasScript">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasScript" :sm="16" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Selecteer een voicelog"
                        name="scriptID">
            <a-select v-model:value="form.scriptID"
                      :allowClear="true"
                      :disabled="!$can('update', 'product') || !form.hasScript" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in scripts" :key="index"
                              :value="item.id" name="scriptID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Pdf teksten">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct5">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bedenktijd?"
                        name="hasCoolOffPeriod">
            <a-select v-model:value="form.hasCoolOffPeriod" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.coolOffPeriodText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasCoolOffPeriod ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Bedenktijd tekst"
            name="coolOffPeriodText"
           >
            <a-input
             v-model:value="form.coolOffPeriodText"
             :disabled="!$can('update', 'product') || !form.hasCoolOffPeriod"
             name="coolOffPeriodText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Boetevergoeding?"
                        name="energyHasFineCompensation">
            <a-select v-model:value="form.energyHasFineCompensation" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.energyFineCompensationText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasFineCompensation">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.energyHasFineCompensation ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Boetevergoeding tekst"
            name="energyFineCompensationText"
           >
            <a-input
             v-model:value="form.energyFineCompensationText"
             :disabled="!$can('update', 'product') || !form.energyHasFineCompensation"
             name="energyFineCompensationText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Boete bij beëindiging?"
                        name="hasFineOnTermination">
            <a-select v-model:value="form.hasFineOnTermination" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.fineOnTerminationText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasFineOnTermination">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasFineOnTermination ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Boete bij beëindiging tekst"
            name="fineOnTerminationText"
           >
            <a-input
             v-model:value="form.fineOnTerminationText"
             :disabled="!$can('update', 'product') || !form.hasFineOnTermination"
             name="fineOnTerminationText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Incentive?"
                        name="hasIncentive">
            <a-select v-model:value="form.hasIncentive" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.incentiveText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasIncentive">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasIncentive ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Incentive tekst"
            name="incentiveText"
           >
            <a-input
             v-model:value="form.incentiveText"
             :disabled="!$can('update', 'product') || !form.hasIncentive"
             name="incentiveText"
             type="text"
            />
           </a-form-item>
          </a-col>
          <a-col :xs="24" class="mb-25">
           <a-form-item
            :rules="{required: true,message: 'Dit veld is verplicht'}"
            label="Tarief Soort"
            name="energyTariffTypeText"
           >
            <a-input
             v-model:value="form.energyTariffTypeText"
             :disabled="!$can('update', 'product')"
             name="energyTariffTypeText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Switch venster">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct9">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Minimum in dagen"
                        name="energySwitchWindowMinDays">
            <a-input v-model:value="form.energySwitchWindowMinDays"
                     :addon-after="!form.energySwitchWindowMinDays || form.energySwitchWindowMinDays === '0' || form.energySwitchWindowMinDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Maximum in dagen"
                        name="energySwitchWindowMaxDays">
            <a-input v-model:value="form.energySwitchWindowMaxDays"
                     :addon-after="!form.energySwitchWindowMaxDays || form.energySwitchWindowMaxDays === '0' || form.energySwitchWindowMaxDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Standaard in dagen"
                        name="energySwitchWindowDefaultDays">
            <a-input v-model:value="form.energySwitchWindowDefaultDays"
                     :addon-after="!form.energySwitchWindowDefaultDays || form.energySwitchWindowDefaultDays === '0' || form.energySwitchWindowDefaultDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Vaste startdag"
                        name="energyHasFixedStartDay">
            <a-select v-model:value="form.energyHasFixedStartDay" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.energyFixedStartDay = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasFixedStartDay">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Startdag"
                        name="energyFixedStartDay">
            <a-input v-model:value="form.energyFixedStartDay"
                     :disabled="!$can('update', 'product') || !form.energyHasFixedStartDay"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>


         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="EAN Blocker">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct10">
         <a-row :gutter="30">
          <a-col :sm="12" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bestaande EAN's blokkeren"
                        name="energyHasEanBlocker">
            <a-select v-model:value="form.energyHasEanBlocker" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.energyEanBlockerDays = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasEanBlocker">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24" class="mb-25">
           <a-form-item :rules="form.energyHasEanBlocker ? {required: true,message: 'Dit veld is verplicht'}: ''"
                        label="Aantal dagen blokkeren"
                        name="energyEanBlockerDays">
            <a-input v-model:value="form.energyEanBlockerDays"
                     :addon-after="!form.energyEanBlockerDays || form.energyEanBlockerDays === '0' || form.energyEanBlockerDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')||!form.energyHasEanBlocker"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Verbruik limitaties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct11">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Limitatie type"
                        name="energyUsageLimitationType">
            <a-select v-model:value="form.energyUsageLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="cleanLimitations">
             <a-select-option v-for="(item,index) in ProductEnergyUsageLimitationType"
                              :key="index"
                              :value="item.value" name="energyUsageLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row v-if="form.energyUsageLimitationType !== 'NONE'" :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Minimale stroom verbruik" name="energyMinElectricityUsage">
            <a-input v-model:value="form.energyMinElectricityUsage" :disabled="!$can('update', 'product')"
                     addon-after="kWh"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Maximale stroom verbruik" name="energyMaxElectricityUsage">
            <a-input v-model:value="form.energyMaxElectricityUsage" :disabled="!$can('update', 'product')"
                     addon-after="kWh"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row v-if="form.energyUsageLimitationType !== 'NONE'" :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Minimale gas verbruik" name="energyMinGasUsage">
            <a-input v-model:value="form.energyMinGasUsage" :disabled="!$can('update', 'product')"
                     addon-after="m3"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Maximale gas verbruik" name="energyMaxGasUsage">
            <a-input v-model:value="form.energyMaxGasUsage" :disabled="!$can('update', 'product')"
                     addon-after="m3"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>

     <div Vergoedingen class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Vergoedingen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateCompensation">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Elektriciteit" name="compensationAmountElectricity">
            <a-input v-model:value="form.compensationAmountElectricity" :disabled="!$can('update', 'product')"
                     addon-before="€"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Gas" name="compensationAmountGas">
            <a-input v-model:value="form.compensationAmountGas" :disabled="!$can('update', 'product')"
                     addon-before="€"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 13 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 13 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="2">
     <template #tab>
      <span>
      <sdFeatherIcons size="18" type="dollar-sign"/>
      Tarieven
       </span>
     </template>
     <div class="user-info-form">
      <sdModal :onCancel="handleAddTariffModalCancel" :visible="addTariffModalVisible" title="Tarief" type="primary"
               width="90%">
       <div style="background:transparent !important;min-height: 1px !important">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <a-form :model="addTariff" @finish="handleAddTariff">
           <a-row :gutter="30">
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Actief"
                          name="active">
              <a-select v-model:value="addTariff.active" :allowClear="true"
                        :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" name="active"
                        size="large">
               <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                                name="active">
                {{ item.key }}
               </a-select-option>
              </a-select>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van" name="validFrom"
                          style="min-height: 48px;margin-bottom: 25px;">
              <DatePickerWrapper>
               <a-date-picker v-model:value="addTariff.validFrom"
                              :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" :format="dateFormat"
                              class="w-100"/>
              </DatePickerWrapper>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot en met"
                          name="validTill"
                          style="min-height: 48px;margin-bottom: 25px;">
              <DatePickerWrapper>
               <a-date-picker v-model:value="addTariff.validTill"
                              :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" :format="dateFormat"
                              class="w-100"/>
              </DatePickerWrapper>
             </a-form-item>
            </a-col>
           </a-row>
           <a-row v-if="form.energyType === 'DUAL' || form.energyType === 'ELECTRICITY'" :gutter="30">
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Enkeltarief"
                          name="tariffSingle"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffSingle"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per kWh"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Normaaltarief"
                          name="tariffHigh"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffHigh"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per kWh"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Daltarief" name="tariffLow"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffLow"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per kWh"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Teruglevering"
                          name="tariffReturnSingle"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffReturnSingle"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per kWh"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Teruglevering normaal"
                          name="tariffReturnHigh"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffReturnHigh"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per kWh"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Teruglevering dal"
                          name="tariffReturnLow"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffReturnLow"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per kWh"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Vaste kosten stroom"
                          name="tariffFixedEnergy"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffFixedEnergy"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per jaar"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
           </a-row>
           <a-row v-if="form.energyType === 'DUAL' || form.energyType === 'GAS'" :gutter="30">
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Leveringstarief gas (G1)"
                          name="tariffGas1"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffGas1"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per m3"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Leveringstarief gas (G2)"
                          name="tariffGas2"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffGas2"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per m3"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Vaste kosten gas"
                          name="tariffFixedGas"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addTariff.tariffFixedGas"
                       :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" addon-after="per paar"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
           </a-row>
           <a-row v-if="form.hasExtendedEnergyTariffLimitation" :gutter="30">
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Startdatum van"
                          name="startDateFrom"
                          style="min-height: 48px;margin-bottom: 25px;">
              <DatePickerWrapper>
               <a-date-picker v-model:value="addTariff.startDateFrom"
                              :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" :format="dateFormat"
                              class="w-100"/>
              </DatePickerWrapper>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Startdatum tot"
                          name="startDateTill"
                          style="min-height: 48px;margin-bottom: 25px;">
              <DatePickerWrapper>
               <a-date-picker v-model:value="addTariff.startDateTill"
                              :disabled="!$can('update', 'product')|| addOrShowTariff === 'EDIT'" :format="dateFormat"
                              class="w-100"/>
              </DatePickerWrapper>
             </a-form-item>
            </a-col>
           </a-row>
           <a-row>
            <a-col :xs="24">
             <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="primary">Opslaan</sdButton>
             </div>
            </a-col>
           </a-row>
          </a-form>
         </BasicFormWrapper>
        </div>
       </div>
      </sdModal>
      <BasicFormWrapper class="mb-25">
       <sdCards title="Prijzen">
        <a-skeleton v-if="loadingProduct" active/>
        <template v-else>
         <TariffListTable @showTariffEdit="handleShowTariffEdit($event)"/>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton class="btn-signin" type="primary" @click.prevent="handleAddTariffModalVisible">
             Toevoegen
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </template>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="3">
     <template #tab>
      <span>
      <sdFeatherIcons size="18" type="message-square"/>
      Vragen en accoderingen
       </span>
     </template>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Productvragen">
        <sdModal :onCancel="handleProductFlowQuestionsModalCancel" :visible="visibleProductFlowQuestions"
                 title="Productvraag toevoegen" type="primary">
         <ModalMain>
          <div class="create-account-form">
           <a-form :model="{selectedAddProductFlowQuestion:selectedAddProductFlowQuestion}" layout="vertical"
                   @finish="handleAddQuestion">
            <a-row :gutter="30">
             <a-col :xs="24" class="mb-25">
              <a-form-item :name="['selectedAddProductFlowQuestion',index]" label="Selecteer een vraag">
               <a-select v-model:value="selectedAddProductFlowQuestion" :allowClear="true"
                         class="sDash_fullwidth-select" size="large">
                <a-select-option
                 v-for="(item,index) in questionOptions"
                 :key="index"
                 :name="['selectedAddProductFlowQuestion', index]" :value="item.id">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :xs="24"
                    style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
              <sdButton class="ml-4" size="small" type="primary">
               <sdFeatherIcons size="14" type="plus"/>
               Toevoegen
              </sdButton>
             </a-col>
            </a-row>
           </a-form>
          </div>
         </ModalMain>
        </sdModal>
        <a-skeleton v-if="loadingFlowQuestions" active class="mb-2"/>
        <a-form :model="{productFlowQuestions:productFlowQuestions}" layout="vertical" @finish="UpdateProduct6">
         <a-row v-if="productFlowQuestions.length > 0" :gutter="30">
          <template v-for="(item, index) in productFlowQuestions" :key="index">
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item label="Geselecteerde vraag">
             <a-select v-model:value="item.flowQuestion.id" :allowClear="true" :disabled="true"
                       class="sDash_fullwidth-select" size="large">
              <a-select-option v-for="(item,index) in questionOptions"
                               :key="index" :value="item.id" style="color:#000000">
               {{ item.name }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'displayName']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Weergavenaam">
             <a-input v-model:value="item.displayName" name="displayName" type="text"/>
            </a-form-item>
           </a-col>
           <a-col :sm="4" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'isRequired']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Verplicht">
             <a-select v-model:value="item.isRequired" :allowClear="true"
                       :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                       size="large">
              <a-select-option v-for="(item,index) in defaultOptions"
                               :key="index"
                               :name="['productFlowQuestions', index, 'isRequired']" :value="item.value">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :xs="4" style="vertical-align: middle;display: flex;justify-content: end;">
            <a-form-item label=" ">
             <sdButton class="ml-4" size="small" type="danger"
                       @click.prevent="handleDeleteProductFlowQuestions(index)">
              <sdFeatherIcons size="14" type="trash"/>
             </sdButton>
            </a-form-item>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product') && questionOptions.length > 0" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddProductFlowQuestions">
             Toevoegen
            </a-button>
            <a-button v-if="productFlowQuestions.length > 0"
                      :disabled="loadingPatchProduct && selectedUpdateButton === 6"
                      :loading="loadingPatchProduct && selectedUpdateButton === 6" class="btn-signin"
                      html-type="submit"
                      size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Accoderingen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form :model="{customerApprovals:form.customerApprovals}" layout="vertical" @finish="UpdateProduct7">
         <a-row v-if="form.customerApprovals && form.customerApprovals.length > 0" :gutter="30">
          <template v-for="(item, index) in form.customerApprovals" :key="index">
           <a-col :sm="20" :xs="24" class="mb-25">
            <a-form-item :name="['customerApprovals', index]"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <a-input v-model:value="form.customerApprovals[index]" :name="['customerApprovals', index]"
                      type="text"/>
            </a-form-item>
           </a-col>
           <a-col :xs="4"
                  style="vertical-align: middle;display: flex;justify-content: end;">
            <sdButton class="ml-4" size="small" type="danger"
                      @click.prevent="handleDeleteCustomerApprovals(index)">
             <sdFeatherIcons size="14" type="trash"/>
            </sdButton>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddCustomerApprovals">
             Toevoegen
            </a-button>
            <sdButton
             v-if="form.customerApprovals && form.customerApprovals.length > 0 && $can('update', 'product')"
             class="btn-signin" type="primary"> Opslaan
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="4">
     <template #tab>
      <span>
      <sdFeatherIcons size="18" type="archive"/>
      Bestanden
       </span>
     </template>
     <div class="user-info-form">
      <sdCards title="Product bestanden">
       <FilesListTable/>
       <a-row class="mt-6">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
           Toevoegen
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
   </TabBasic>
   <TabBasic
    v-if="form.type !== null && form.type === 'HYDROMX'"
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
    @tabClick="handleTabHydromx"
   >
    <Child :key="1">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Overview
            </span>
     </template>
     <a-row :gutter="15" type="flex">
      <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
       <Upload style="flex: 1 1 auto;">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
           <a-skeleton v-if="loadingProduct" active/>
           <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct1">
            <a-row :gutter="30">
             <a-col :sm="24" :xs="24" class="mb-25">
              <figure class="photo-upload align-center-v">
               <img
                v-if="form.imageFileURL && form.imageFileURL !== ''"
                :src="form.imageFileURL"
                alt=""
                style="max-width: 120px"
               />
               <img
                v-else
                :src="require('@/static/img/avatar/profileImage.png')"
                alt=""
                style="max-width: 120px"
               />
               <figcaption>
                <a-upload
                 :action="`${fileUrl}products/${form.id}/file`"
                 :disabled="!$can('update', 'product')"
                 :headers="fileUploadHeaders"
                 :multiple="false"
                 name="file"
                 @change="handleUpload">
                 <a class="btn-upload" to="#">
                  <sdFeatherIcons size="16" type="camera"/>
                 </a>
                </a-upload>
               </figcaption>
              </figure>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                           name="name">
               <a-input v-model:value="form.name" :disabled="!$can('update', 'product')" placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                           name="type">
               <a-select v-model:value="form.type" :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in productTypes" :key="index"
                                 :value="item.key" name="type">
                 {{ item.value }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Tonen in offerte flow?"
                           name="hasEsignatureSigning">
               <a-select v-model:value="form.hasEsignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasEsignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label=" Tonen in overeenkomsten flow"
                           name="hasSignatureSigning">
               <a-select v-model:value="form.hasSignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasSignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van"
                           name="validFrom"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validFrom" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot"
                           name="validTill"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validTill" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
            </a-row>
            <a-row>
             <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
               <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 1 && loadingPatchProduct"
                         :loading="selectedUpdateButton === 1 && loadingPatchProduct" class="btn-signin"
                         html-type="submit" size="large"
                         type="primary"> Opslaan
               </a-button>
              </div>
             </a-col>
            </a-row>
           </a-form>
          </sdCards>
         </BasicFormWrapper>
        </div>
       </Upload>
      </a-col>
      <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
       <sdCards :style="!$can('update', 'product')?'flex: 1 1 auto;':''" title="Informatie">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <InfoWrapper>
           <div class="info-title">ID</div>
           <div class="info-text">{{ form.id }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Status</div>
           <div class="info-text"><span :style="`color: white;background-color: ${form.active?'#0CAB7C':'#E30D0F'}`"
                                        className="status-text">{{
             form.active ? 'Actief' : 'Inactief'
            }}</span></div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Revisie ID</div>
           <div class="info-text">{{ form.parentID ? form.parentID : 'Geen revisie beschikbaar' }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum aangemaakt</div>
           <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum geupdate</div>
           <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
         </a-col>
        </a-row>
       </sdCards>
       <sdCards v-if="$can('update', 'product')" style="flex: 1 1 auto;" title="Acties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchProduct && selectedUpdateButton === 12"
                     :loading="loadingPatchProduct && selectedUpdateButton ===12"
                     :type="form.active? 'danger' : 'primary'"
                     class="btn-signin w-100"
                     size="large"
                     @click.prevent="activateProduct">
            <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
            <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
            {{ form.active ? 'Deactiveren' : 'Activeren' }}
           </a-button>
          </div>
         </a-col>
        </a-row>
       </sdCards>
      </a-col>
     </a-row>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Product omschrijving">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">
         <a-row :gutter="30">
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Extra informatie">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.description"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Unique Selling Point">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.uspText"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Eigenschappen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct3">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zichtbaarheid op basis van BAG resultaat"
                        name="energyBagLimitationType">
            <a-select v-model:value="form.energyBagLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in bagLimitationOptions" :key="index"
                              :value="item.value" name="energyBagLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Verloop type voor offertes"
                        name="expiryType">
            <a-select v-model:value="form.expiryType" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in productExpiryTypeOptions" :key="index"
                              :value="item.value" name="expiryType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.expiryType === 'FIXEDDAYS'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte verloopdatum"
                        name="expiryDays">
            <a-input v-model:value="form.expiryDays"
                     :addon-after="!form.expiryDays || form.expiryDays === '0' || form.expiryDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.overrideSender && form.type !== null" :sm="8" :xs="24"
                 class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender"
                        name="overrideSenderID">
            <a-select v-model:value="form.overrideSenderID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in senders" :key="index"
                              :value="item.id" name="senderID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 3 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 3 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Scripts">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct12">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Voicelog?"
                        name="hasScript">
            <a-select v-model:value="form.hasScript" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.scriptID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasScript">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasScript" :sm="16" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Selecteer een voicelog"
                        name="scriptID">
            <a-select v-model:value="form.scriptID"
                      :allowClear="true"
                      :disabled="!$can('update', 'product') || !form.hasScript" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in scripts" :key="index"
                              :value="item.id" name="scriptID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="2">
     <template #tab>
            <span @click="loadProductFilesTab">
              <sdFeatherIcons size="18" type="archive"/>
              Bestanden
            </span>
     </template>
     <div class="user-info-form">
      <sdCards title="Product bestanden">
       <FilesListTable/>
       <a-row class="mt-6">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
           Toevoegen
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
   </TabBasic>
   <TabBasic
    v-if="form.type !== null && form.type === 'SVM'"
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
   >
    <Child :key="1">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Overview
            </span>
     </template>
     <a-row :gutter="15" type="flex">
      <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
       <Upload style="flex: 1 1 auto;">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
           <a-skeleton v-if="loadingProduct" active/>
           <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct1">
            <a-row :gutter="30">
             <a-col :sm="24" :xs="24" class="mb-25">
              <figure class="photo-upload align-center-v">
               <img
                v-if="form.imageFileURL && form.imageFileURL !== ''"
                :src="form.imageFileURL"
                alt=""
                style="max-width: 120px"
               />
               <img
                v-else
                :src="require('@/static/img/avatar/profileImage.png')"
                alt=""
                style="max-width: 120px"
               />
               <figcaption>
                <a-upload
                 :action="`${fileUrl}products/${form.id}/file`"
                 :disabled="!$can('update', 'product')"
                 :headers="fileUploadHeaders"
                 :multiple="false"
                 name="file"
                 @change="handleUpload">
                 <a class="btn-upload" to="#">
                  <sdFeatherIcons size="16" type="camera"/>
                 </a>
                </a-upload>
               </figcaption>
              </figure>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                           name="name">
               <a-input v-model:value="form.name" :disabled="!$can('update', 'product')" placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                           name="type">
               <a-select v-model:value="form.type" :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in productTypes" :key="index"
                                 :value="item.key" name="type">
                 {{ item.value }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Tonen in offerte flow?"
                           name="hasEsignatureSigning">
               <a-select v-model:value="form.hasEsignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasEsignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label=" Tonen in overeenkomsten flow"
                           name="hasSignatureSigning">
               <a-select v-model:value="form.hasSignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasSignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van"
                           name="validFrom"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validFrom" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot"
                           name="validTill"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validTill" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
            </a-row>
            <a-row>
             <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
               <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 1 && loadingPatchProduct"
                         :loading="selectedUpdateButton === 1 && loadingPatchProduct" class="btn-signin"
                         html-type="submit" size="large"
                         type="primary"> Opslaan
               </a-button>
              </div>
             </a-col>
            </a-row>
           </a-form>
          </sdCards>
         </BasicFormWrapper>
        </div>
       </Upload>
      </a-col>
      <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
       <sdCards :style="!$can('update', 'product')?'flex: 1 1 auto;':''" title="Informatie">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <InfoWrapper>
           <div class="info-title">ID</div>
           <div class="info-text">{{ form.id }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Status</div>
           <div class="info-text"><span :style="`color: white;background-color: ${form.active?'#0CAB7C':'#E30D0F'}`"
                                        className="status-text">{{
             form.active ? 'Actief' : 'Inactief'
            }}</span></div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Revisie ID</div>
           <div class="info-text">{{ form.parentID ? form.parentID : 'Geen revisie beschikbaar' }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum aangemaakt</div>
           <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum geupdate</div>
           <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
         </a-col>
        </a-row>
       </sdCards>
       <sdCards v-if="$can('update', 'product')" style="flex: 1 1 auto;" title="Acties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchProduct && selectedUpdateButton === 12"
                     :loading="loadingPatchProduct && selectedUpdateButton ===12"
                     :type="form.active? 'danger' : 'primary'"
                     class="btn-signin w-100"
                     size="large"
                     @click.prevent="activateProduct">
            <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
            <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
            {{ form.active ? 'Deactiveren' : 'Activeren' }}
           </a-button>
          </div>
         </a-col>
        </a-row>
       </sdCards>
      </a-col>
     </a-row>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Product omschrijving">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">
         <a-row :gutter="30">
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Extra informatie">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.description"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Unique Selling Point">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.uspText"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Eigenschappen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct3">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zichtbaarheid op basis van BAG resultaat"
                        name="energyBagLimitationType">
            <a-select v-model:value="form.energyBagLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in bagLimitationOptions" :key="index"
                              :value="item.value" name="energyBagLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Verloop type voor offertes"
                        name="expiryType">
            <a-select v-model:value="form.expiryType" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in productExpiryTypeOptions" :key="index"
                              :value="item.value" name="expiryType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.expiryType === 'FIXEDDAYS'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte verloopdatum"
                        name="expiryDays">
            <a-input v-model:value="form.expiryDays"
                     :addon-after="!form.expiryDays || form.expiryDays === '0' || form.expiryDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.overrideSender && form.type !== null" :sm="8" :xs="24"
                 class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender"
                        name="overrideSenderID">
            <a-select v-model:value="form.overrideSenderID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in senders" :key="index"
                              :value="item.id" name="senderID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 3 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 3 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Scripts">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct12">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Voicelog?"
                        name="hasScript">
            <a-select v-model:value="form.hasScript" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.scriptID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasScript">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasScript" :sm="16" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Selecteer een voicelog"
                        name="scriptID">
            <a-select v-model:value="form.scriptID"
                      :allowClear="true"
                      :disabled="!$can('update', 'product') || !form.hasScript" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in scripts" :key="index"
                              :value="item.id" name="scriptID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
   </TabBasic>
   <TabBasic
    v-if="form.type !== null && form.type === 'SOLARSIMPLE'"
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
    @tabClick="handleTabSolarSimple"
   >
    <Child :key="1">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Overview
            </span>
     </template>
     <a-row :gutter="15" type="flex">
      <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
       <Upload style="flex: 1 1 auto;">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
           <a-skeleton v-if="loadingProduct" active/>
           <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct1">
            <a-row :gutter="30">
             <a-col :sm="12" :xs="12" class="mb-25">
              <a-form-item label="Product foto" name=""
                           rules="">
               <figure class="photo-upload align-center-v">
                <img
                 v-if="form.imageFileURL && form.imageFileURL !== ''"
                 :src="form.imageFileURL"
                 alt=""
                 style="max-width: 120px"
                />
                <img
                 v-else
                 :src="require('@/static/img/avatar/profileImage.png')"
                 alt=""
                 style="max-width: 120px"
                />
                <figcaption>
                 <a-upload
                  :action="`${fileUrl}products/${form.id}/file`"
                  :disabled="!$can('update', 'product')"
                  :headers="fileUploadHeaders"
                  :multiple="false"
                  name="file"
                  @change="handleUpload">
                  <a class="btn-upload" to="#">
                   <sdFeatherIcons size="16" type="camera"/>
                  </a>
                 </a-upload>
                </figcaption>
               </figure>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="12" class="mb-25">
              <a-form-item label="Banner foto" name=""
                           rules="">
               <figure class="photo-upload align-center-v">
                <img
                 v-if="form.secondaryImageFileURL && form.secondaryImageFileURL !== ''"
                 :src="form.secondaryImageFileURL"
                 alt=""
                 style="max-width: 120px"
                />
                <img
                 v-else
                 :src="require('@/static/img/avatar/profileImage.png')"
                 alt=""
                 style="max-width: 120px"
                />
                <figcaption>
                 <a-upload
                  :action="`${fileUrl}products/${form.id}/secondaryfile`"
                  :disabled="!$can('update', 'product')"
                  :headers="fileUploadHeaders"
                  :multiple="false"
                  name="file"
                  @change="handleUpload">
                  <a class="btn-upload" to="#">
                   <sdFeatherIcons size="16" type="camera"/>
                  </a>
                 </a-upload>
                </figcaption>
               </figure>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                           name="name">
               <a-input v-model:value="form.name" :disabled="!$can('update', 'product')" placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && (form.type === 'ENERGY' || form.type === 'SOLARSIMPLE')" :sm="12"
                    :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Leverancier"
                           name="supplierID">
               <a-select v-model:value="form.supplierID" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in suppliers" :key="index"
                                 :value="item.id" name="supplierID">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                           name="type">
               <a-select v-model:value="form.type" :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in productTypes" :key="index"
                                 :value="item.key" name="type">
                 {{ item.value }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Energie type"
                           name="energyType">
               <a-select v-model:value="form.energyType" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in energyTypeOptions" :key="index"
                                 :value="item.value" name="energyType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Retentie"
                           name="retentionType">
               <a-select v-model:value="form.retentionType" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in retentionTypeOptions" :key="index"
                                 :value="item.value" name="retentionType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Looptijd type"
                           name="contractDurationType">
               <a-select v-model:value="form.contractDurationType"
                         :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select" size="large"
                         @change="form.contractDurationMonths = null;form.contractDurationDate = '';">
                <a-select-option v-for="(item,index) in contractDurationType"
                                 :key="index"
                                 :value="item.value" name="contractDurationType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.contractDurationType === 'MONTHS'"
                    :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Contractduur"
                           name="contractDurationMonths">
               <a-input v-model:value="form.contractDurationMonths"
                        :addon-after="!form.contractDurationMonths || form.contractDurationMonths === '0' || form.contractDurationMonths === '1'  ? 'maand' : 'maanden'"
                        :disabled="!$can('update', 'product')"
                        placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.contractDurationType === 'DATE'" :sm="12"
                    :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Vaste einddatum"
                           name="contractDurationDate">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.contractDurationDate" :disabled="!$can('update', 'product')"
                               :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.energyType === 'DUAL'" :sm="12" :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label="Beschikbaar als Stroom Single Fuel"
                           name="energyAllowSingleFuelElectricity">
               <a-select v-model:value="form.energyAllowSingleFuelElectricity"
                         :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions"
                                 :key="index"
                                 :value="item.value" name="energyAllowSingleFuelElectricity">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.energyType === 'DUAL'" :sm="12" :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label="Beschikbaar als Gas Single Fuel"
                           name="energyAllowSingleFuelGas">
               <a-select v-model:value="form.energyAllowSingleFuelGas"
                         :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="energyAllowSingleFuelGas">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Zakelijk of Consument"
                           name="allowedCustomerType">
               <a-select v-model:value="form.allowedCustomerType" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in customerTypeOptions" :key="index"
                                 :value="item.value" name="allowedCustomerType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Is een machtiging?"
                           name="isProxy">
               <a-select v-model:value="form.isProxy" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="isProxy">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Tonen in offerte flow?"
                           name="hasEsignatureSigning">
               <a-select v-model:value="form.hasEsignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasEsignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label=" Tonen in overeenkomsten flow"
                           name="hasSignatureSigning">
               <a-select v-model:value="form.hasSignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasSignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van"
                           name="validFrom"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validFrom" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot"
                           name="validTill"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validTill" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
            </a-row>
            <a-row>
             <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
               <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 1 && loadingPatchProduct"
                         :loading="selectedUpdateButton === 1 && loadingPatchProduct" class="btn-signin"
                         html-type="submit" size="large"
                         type="primary"> Opslaan
               </a-button>
              </div>
             </a-col>
            </a-row>
           </a-form>
          </sdCards>
         </BasicFormWrapper>
        </div>
       </Upload>
      </a-col>
      <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
       <sdCards :style="!$can('update', 'product')?'flex: 1 1 auto;':''" title="Informatie">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <InfoWrapper>
           <div class="info-title">ID</div>
           <div class="info-text">{{ form.id }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Status</div>
           <div class="info-text"><span :style="`color: white;background-color: ${form.active?'#0CAB7C':'#E30D0F'}`"
                                        className="status-text">{{
             form.active ? 'Actief' : 'Inactief'
            }}</span></div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Revisie ID</div>
           <div class="info-text">{{ form.parentID ? form.parentID : 'Geen revisie beschikbaar' }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum aangemaakt</div>
           <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum geupdate</div>
           <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
         </a-col>
        </a-row>
       </sdCards>
       <sdCards v-if="$can('update', 'product')" style="flex: 1 1 auto;" title="Acties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchProduct && selectedUpdateButton === 12"
                     :loading="loadingPatchProduct && selectedUpdateButton ===12"
                     :type="form.active? 'danger' : 'primary'"
                     class="btn-signin w-100"
                     size="large"
                     @click.prevent="activateProduct">
            <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
            <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
            {{ form.active ? 'Deactiveren' : 'Activeren' }}
           </a-button>
          </div>
         </a-col>
         <a-col v-if="!form.isProxy && form.type !== null && form.type === 'ENERGY'" :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingSyncTariffForProduct" :loading="loadingSyncTariffForProduct"
                     class="btn-signin w-100"
                     size="large"
                     type="primary"
                     @click.prevent="syncTariff">
            <sdFeatherIcons class="mr-2" size="16" type="refresh-cw"/>
            Tarieven syncen
           </a-button>
          </div>
         </a-col>
        </a-row>
       </sdCards>
      </a-col>
     </a-row>

     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Product omschrijving">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">
         <a-row :gutter="30">
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Extra informatie">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.description"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Unique Selling Point">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.uspText"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <sdModal :onCancel="handleAddSubProductModalCancel" :visible="addSubProductModalVisible"
               title="Prijs" type="primary"
               width="90%">
       <div style="background:transparent !important;min-height: 1px !important">
        <div class="user-info-form">
         <Upload>
          <a-row v-if="addOrShowSubProduct === 'EDIT'" :gutter="30">
           <a-col :sm="24" :xs="24">
            <a-form-item label="Product foto" name=""
                         rules="">
             <figure class="photo-upload align-center-v">

              <img
               v-if="addSubProduct.imageFileURL && addSubProduct.imageFileURL !== ''"
               :src="addSubProduct.imageFileURL"
               alt=""
               style="max-width: 120px;borderRadius:0px !important;"
              />
              <img
               v-else
               :src="require('@/static/img/avatar/profileImage.png')"
               alt=""
               style="max-width: 120px;borderRadius:0px !important;"
              />
              <figcaption>
               <a-upload
                :action="`${fileUrl}products/${form.id}/solarsubproducts/${addSubProduct.id}/file`"
                :disabled="!$can('update', 'product')"
                :headers="fileUploadHeaders"
                :multiple="false"
                name="file"
                @change="handleUpload">
                <a class="btn-upload" to="#">
                 <sdFeatherIcons size="16" type="camera"/>
                </a>
               </a-upload>
              </figcaption>
             </figure>
            </a-form-item>
           </a-col>
          </a-row>
         </Upload>
         <BasicFormWrapper class="mb-25">
          <a-form :model="addSubProduct" @finish="handleAddSubProduct">
           <a-row :gutter="30">
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Actief"
                          name="active">
              <a-select v-model:value="addSubProduct.active" :allowClear="true"
                        :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" name="active"
                        size="large">
               <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                                name="active">
                {{ item.key }}
               </a-select-option>
              </a-select>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                          name="name"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addSubProduct.name"
                       :disabled="!$can('update', 'product')"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Korte omschrijving"
                          name="description"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addSubProduct.description"
                       :disabled="!$can('update', 'product')"
                       placeholder=""/>
             </a-form-item>
            </a-col>
           </a-row>
           <a-row>
            <a-col :xs="24">
             <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="primary">Opslaan</sdButton>
             </div>
            </a-col>
           </a-row>
          </a-form>
         </BasicFormWrapper>
        </div>
       </div>
      </sdModal>
      <BasicFormWrapper class="mb-25">
       <sdCards title="Bijproducten">
        <a-skeleton v-if="loadingProduct" active/>
        <template v-else>
         <SolarSimpleSubProduct ref="solarSimpleSubProductRef" @delete="handleDeleteSubProduct($event)"
                                @edit="handleShowSubProductEdit($event)"/>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton class="btn-signin" type="primary" @click.prevent="handleAddSubProductModalVisible($event)">
             Toevoegen
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </template>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Eigenschappen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct3">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Heeft een vast aantal panelen?"
                        name="hasFixedPanelCount">
            <a-select v-model:value="form.hasFixedPanelCount" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.fixedPanelCount = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasFixedPanelCount">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasFixedPanelCount ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Vaste aantal panelen"
            name="fixedPanelCount"
           >
            <a-input
             v-model:value="form.fixedPanelCount"
             :disabled="!$can('update', 'product') || !form.hasFixedPanelCount"
             name="fixedPanelCount"
             type="number"
            />
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zichtbaarheid op basis van BAG resultaat"
                        name="energyBagLimitationType">
            <a-select v-model:value="form.energyBagLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in bagLimitationOptions" :key="index"
                              :value="item.value" name="energyBagLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Verloop type voor offertes"
                        name="expiryType">
            <a-select v-model:value="form.expiryType" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in productExpiryTypeOptions" :key="index"
                              :value="item.value" name="expiryType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.expiryType === 'FIXEDDAYS'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte verloopdatum"
                        name="expiryDays">
            <a-input v-model:value="form.expiryDays"
                     :addon-after="!form.expiryDays || form.expiryDays === '0' || form.expiryDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                        label="Zonnepaneel vermogen"
                        name="solarPanelPower">
            <a-input v-model:value="form.solarPanelPower"
                     :disabled="!$can('update', 'product')"
                     addon-after="wattpiek"
                     placeholder=""
                     type="text"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 3 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 3 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Templates">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct4">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Pdf Template"
                        name="pdfTemplateID">
            <a-select v-model:value="form.pdfTemplateID" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.PDF" :key="index"
                              :value="item.id" name="pdfTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email offerte template"
                        name="offerEmailTemplateID">
            <a-select v-model:value="form.offerEmailTemplateID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="offerEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bevestigingsmail versturen"
                        name="hasConfirmationEmail">
            <a-select v-model:value="form.hasConfirmationEmail" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.confirmationEmailTemplateID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasConfirmationEmail" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email bevestiging template"
                        name="confirmationEmailTemplateID">
            <a-select v-model:value="form.confirmationEmailTemplateID"
                      :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="confirmationEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Scripts">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct12">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Voicelog?"
                        name="hasScript">
            <a-select v-model:value="form.hasScript" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.scriptID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasScript">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasScript" :sm="16" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Selecteer een voicelog"
                        name="scriptID">
            <a-select v-model:value="form.scriptID"
                      :allowClear="true"
                      :disabled="!$can('update', 'product') || !form.hasScript" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in scripts" :key="index"
                              :value="item.id" name="scriptID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div v-if="form.type !== null && form.type === 'ENERGY'" class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Pdf teksten">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct5">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bedenktijd?"
                        name="hasCoolOffPeriod">
            <a-select v-model:value="form.hasCoolOffPeriod" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.coolOffPeriodText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasCoolOffPeriod ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Bedenktijd tekst"
            name="coolOffPeriodText"
           >
            <a-input
             v-model:value="form.coolOffPeriodText"
             :disabled="!$can('update', 'product') || !form.hasCoolOffPeriod"
             name="coolOffPeriodText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Boetevergoeding?"
                        name="energyHasFineCompensation">
            <a-select v-model:value="form.energyHasFineCompensation" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.energyFineCompensationText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasFineCompensation">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.energyHasFineCompensation ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Boetevergoeding tekst"
            name="energyFineCompensationText"
           >
            <a-input
             v-model:value="form.energyFineCompensationText"
             :disabled="!$can('update', 'product') || !form.energyHasFineCompensation"
             name="energyFineCompensationText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Boete bij beëindiging?"
                        name="hasFineOnTermination">
            <a-select v-model:value="form.hasFineOnTermination" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.fineOnTerminationText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasFineOnTermination">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasFineOnTermination ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Boete bij beëindiging tekst"
            name="fineOnTerminationText"
           >
            <a-input
             v-model:value="form.fineOnTerminationText"
             :disabled="!$can('update', 'product') || !form.hasFineOnTermination"
             name="fineOnTerminationText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Incentive?"
                        name="hasIncentive">
            <a-select v-model:value="form.hasIncentive" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.incentiveText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasIncentive">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasIncentive ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Incentive tekst"
            name="incentiveText"
           >
            <a-input
             v-model:value="form.incentiveText"
             :disabled="!$can('update', 'product') || !form.hasIncentive"
             name="incentiveText"
             type="text"
            />
           </a-form-item>
          </a-col>
          <a-col :xs="24" class="mb-25">
           <a-form-item
            :rules="{required: true,message: 'Dit veld is verplicht'}"
            label="Tarief Soort"
            name="energyTariffTypeText"
           >
            <a-input
             v-model:value="form.energyTariffTypeText"
             :disabled="!$can('update', 'product')"
             name="energyTariffTypeText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="2">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="dollar-sign"/>
              Prijzen
            </span>
     </template>
     <div class="user-info-form">
      <sdModal :onCancel="handleAddTariffSolarSimpleModalCancel" :visible="addTariffSolarSimpleModalVisible"
               title="Prijs" type="primary"
               width="90%">
       <div style="background:transparent !important;min-height: 1px !important">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <a-form :model="addSolarSimpleTariff" @finish="handleAddSolarSimpleTariff">
           <a-row :gutter="30">
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Actief"
                          name="active">
              <a-select v-model:value="addSolarSimpleTariff.active" :allowClear="true"
                        :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" name="active"
                        size="large">
               <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                                name="active">
                {{ item.key }}
               </a-select-option>
              </a-select>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van" name="validFrom"
                          style="min-height: 48px;margin-bottom: 25px;">
              <DatePickerWrapper>
               <a-date-picker v-model:value="addSolarSimpleTariff.validFrom"
                              :disabled="!$can('update', 'product')"
                              :format="dateFormat"
                              class="w-100"/>
              </DatePickerWrapper>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot en met"
                          name="validTill"
                          style="min-height: 48px;margin-bottom: 25px;">
              <DatePickerWrapper>
               <a-date-picker v-model:value="addSolarSimpleTariff.validTill"
                              :disabled="!$can('update', 'product')"
                              :format="dateFormat"
                              class="w-100"/>
              </DatePickerWrapper>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Prijs"
                          name="price"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addSolarSimpleTariff.price"
                       :disabled="!$can('update', 'product')"
                       addon-after="per paneel"
                       addon-before="€"
                       onkeydown="if(event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                          label="Panelen van"
                          name="panelCountFrom"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addSolarSimpleTariff.panelCountFrom"
                       :disabled="!$can('update', 'product')"
                       addon-after="aantal panelen"
                       placeholder=""/>
             </a-form-item>
            </a-col>
            <a-col :sm="8" :xs="24" class="mb-25">
             <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                          label="Panelen tot"
                          name="panelCountTill"
                          style="min-height: 48px;margin-bottom: 25px;">
              <a-input v-model:value="addSolarSimpleTariff.panelCountTill"
                       :disabled="!$can('update', 'product')"
                       addon-after="aantal panelen"
                       placeholder=""/>
             </a-form-item>
            </a-col>
           </a-row>
           <a-row>
            <a-col :xs="24">
             <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="primary">Opslaan</sdButton>
             </div>
            </a-col>
           </a-row>
          </a-form>
         </BasicFormWrapper>
        </div>
       </div>
      </sdModal>
      <BasicFormWrapper class="mb-25">
       <sdCards title="Prijzen">
        <a-skeleton v-if="loadingProduct" active/>
        <template v-else>
         <SolarSimpleTariffTable @showSolarSimpleTariffEdit="handleShowSolarSimpleTariffEdit($event)"/>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton class="btn-signin" type="primary" @click.prevent="handleAddTariffSolarSimpleModalVisible">
             Toevoegen
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </template>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="3">
     <template #tab>
            <span @click="loadQuestionsTab">
              <sdFeatherIcons size="18" type="message-square"/>
              Vragen en accoderingen
            </span>
     </template>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Productvragen">
        <sdModal :onCancel="handleProductFlowQuestionsModalCancel" :visible="visibleProductFlowQuestions"
                 title="Productvraag toevoegen" type="primary">
         <ModalMain>
          <div class="create-account-form">
           <a-form :model="{selectedAddProductFlowQuestion:selectedAddProductFlowQuestion}" layout="vertical"
                   @finish="handleAddQuestion">
            <a-row :gutter="30">
             <a-col :xs="24" class="mb-25">
              <a-form-item :name="['selectedAddProductFlowQuestion',index]" label="Selecteer een vraag">
               <a-select v-model:value="selectedAddProductFlowQuestion" :allowClear="true"
                         class="sDash_fullwidth-select" size="large">
                <a-select-option
                 v-for="(item,index) in questionOptions"
                 :key="index"
                 :name="['selectedAddProductFlowQuestion', index]" :value="item.id">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :xs="24" style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
              <sdButton class="ml-4" size="small" type="primary">
               <sdFeatherIcons size="14" type="plus"/>
               Toevoegen
              </sdButton>
             </a-col>
            </a-row>
           </a-form>
          </div>
         </ModalMain>
        </sdModal>
        <a-skeleton v-if="loadingFlowQuestions" active/>
        <a-form :model="{productFlowQuestions:productFlowQuestions}" layout="vertical" @finish="UpdateProduct6">
         <a-row v-if="productFlowQuestions.length > 0" :gutter="30">
          <template v-for="(item, index) in productFlowQuestions" :key="index">
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item label="Geselecteerde vraag">
             <a-select v-model:value="item.flowQuestion.id" :allowClear="true" :disabled="true"
                       class="sDash_fullwidth-select" size="large">
              <a-select-option v-for="(item,index) in questionOptions"
                               :key="index" :value="item.id" style="color:#000000">
               {{ item.name }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'displayName']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Weergavenaam">
             <a-input v-model:value="item.displayName" name="displayName" type="text"/>
            </a-form-item>
           </a-col>
           <a-col :sm="4" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'isRequired']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Verplicht">
             <a-select v-model:value="item.isRequired" :allowClear="true"
                       :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                       size="large">
              <a-select-option v-for="(item,index) in defaultOptions"
                               :key="index"
                               :name="['productFlowQuestions', index, 'isRequired']" :value="item.value">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :xs="4" style="vertical-align: middle;display: flex;justify-content: end;">
            <a-form-item label=" ">
             <sdButton class="ml-4" size="small" type="danger"
                       @click.prevent="handleDeleteProductFlowQuestions(index)">
              <sdFeatherIcons size="14" type="trash"/>
             </sdButton>
            </a-form-item>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product') && questionOptions.length > 0" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddProductFlowQuestions">
             Toevoegen
            </a-button>
            <a-button v-if="productFlowQuestions.length > 0"
                      :disabled="loadingPatchProduct && selectedUpdateButton === 6"
                      :loading="loadingPatchProduct && selectedUpdateButton === 6" class="btn-signin"
                      html-type="submit"
                      size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Accoderingen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form :model="{customerApprovals:form.customerApprovals}" layout="vertical" @finish="UpdateProduct7">
         <a-row v-if="form.customerApprovals && form.customerApprovals.length > 0" :gutter="30">
          <template v-for="(item, index) in form.customerApprovals" :key="index">
           <a-col :sm="20" :xs="24" class="mb-25">
            <a-form-item :name="['customerApprovals', index]"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <a-input v-model:value="form.customerApprovals[index]" :name="['customerApprovals', index]"
                      type="text"/>
            </a-form-item>
           </a-col>
           <a-col :xs="4"
                  style="vertical-align: middle;display: flex;justify-content: end;">
            <sdButton class="ml-4" size="small" type="danger"
                      @click.prevent="handleDeleteCustomerApprovals(index)">
             <sdFeatherIcons size="14" type="trash"/>
            </sdButton>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddCustomerApprovals">
             Toevoegen
            </a-button>
            <sdButton
             v-if="form.customerApprovals && form.customerApprovals.length > 0 && $can('update', 'product')"
             class="btn-signin" type="primary"> Opslaan
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="4">
     <template #tab>
            <span @click="loadProductFilesTab">
              <sdFeatherIcons size="18" type="archive"/>
              Bestanden
            </span>
     </template>
     <div class="user-info-form">
      <sdCards title="Product bestanden">
       <FilesListTable/>
       <a-row class="mt-6">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
           Toevoegen
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
   </TabBasic>
   <TabBasic
    v-if="form.type !== null && form.type === 'SHOP'"
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
    @tabClick="handleTabClickShop"
   >
    <Child :key="1">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Overview
            </span>
     </template>
     <a-row :gutter="15" type="flex">
      <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
       <Upload style="flex: 1 1 auto;">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
           <a-skeleton v-if="loadingProduct" active/>
           <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct1">
            <a-row :gutter="30">
             <a-col :sm="24" :xs="24" class="mb-25">
              <figure class="photo-upload align-center-v">
               <img
                v-if="form.imageFileURL && form.imageFileURL !== ''"
                :src="form.imageFileURL"
                alt=""
                style="max-width: 120px"
               />
               <img
                v-else
                :src="require('@/static/img/avatar/profileImage.png')"
                alt=""
                style="max-width: 120px"
               />
               <figcaption>
                <a-upload
                 :action="`${fileUrl}products/${form.id}/file`"
                 :disabled="!$can('update', 'product')"
                 :headers="fileUploadHeaders"
                 :multiple="false"
                 name="file"
                 @change="handleUpload">
                 <a class="btn-upload" to="#">
                  <sdFeatherIcons size="16" type="camera"/>
                 </a>
                </a-upload>
               </figcaption>
              </figure>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                           name="name">
               <a-input v-model:value="form.name" :disabled="!$can('update', 'product')" placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Leverancier"
                           name="supplierID">
               <a-select v-model:value="form.supplierID" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in suppliers" :key="index"
                                 :value="item.id" name="supplierID">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                           name="type">
               <a-select v-model:value="form.type" :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in productTypes" :key="index"
                                 :value="item.key" name="type">
                 {{ item.value }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Energie type"
                           name="energyType">
               <a-select v-model:value="form.energyType" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in energyTypeOptions" :key="index"
                                 :value="item.value" name="energyType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Retentie"
                           name="retentionType">
               <a-select v-model:value="form.retentionType" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in retentionTypeOptions" :key="index"
                                 :value="item.value" name="retentionType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Looptijd type"
                           name="contractDurationType">
               <a-select v-model:value="form.contractDurationType"
                         :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select" size="large"
                         @change="form.contractDurationMonths = null;form.contractDurationDate = '';">
                <a-select-option v-for="(item,index) in contractDurationType"
                                 :key="index"
                                 :value="item.value" name="contractDurationType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.contractDurationType === 'MONTHS'"
                    :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Contractduur"
                           name="contractDurationMonths">
               <a-input v-model:value="form.contractDurationMonths"
                        :addon-after="!form.contractDurationMonths || form.contractDurationMonths === '0' || form.contractDurationMonths === '1'  ? 'maand' : 'maanden'"
                        :disabled="!$can('update', 'product')"
                        placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.contractDurationType === 'DATE'" :sm="12"
                    :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Vaste einddatum"
                           name="contractDurationDate">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.contractDurationDate" :disabled="!$can('update', 'product')"
                               :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.energyType === 'DUAL'" :sm="12" :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label="Beschikbaar als Stroom Single Fuel"
                           name="energyAllowSingleFuelElectricity">
               <a-select v-model:value="form.energyAllowSingleFuelElectricity"
                         :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions"
                                 :key="index"
                                 :value="item.value" name="energyAllowSingleFuelElectricity">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY' && form.energyType === 'DUAL'" :sm="12" :xs="24"
                    class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label="Beschikbaar als Gas Single Fuel"
                           name="energyAllowSingleFuelGas">
               <a-select v-model:value="form.energyAllowSingleFuelGas"
                         :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="energyAllowSingleFuelGas">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Zakelijk of Consument"
                           name="allowedCustomerType">
               <a-select v-model:value="form.allowedCustomerType" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in customerTypeOptions" :key="index"
                                 :value="item.value" name="allowedCustomerType">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Is een machtiging?"
                           name="isProxy">
               <a-select v-model:value="form.isProxy" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="isProxy">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Tonen in offerte flow?"
                           name="hasEsignatureSigning">
               <a-select v-model:value="form.hasEsignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasEsignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label=" Tonen in overeenkomsten flow"
                           name="hasSignatureSigning">
               <a-select v-model:value="form.hasSignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasSignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van"
                           name="validFrom"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validFrom" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot"
                           name="validTill"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validTill" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
            </a-row>
            <a-row>
             <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
               <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 1 && loadingPatchProduct"
                         :loading="selectedUpdateButton === 1 && loadingPatchProduct" class="btn-signin"
                         html-type="submit" size="large"
                         type="primary"> Opslaan
               </a-button>
              </div>
             </a-col>
            </a-row>
           </a-form>
          </sdCards>
         </BasicFormWrapper>
        </div>
       </Upload>
      </a-col>
      <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
       <sdCards :style="!$can('update', 'product')?'flex: 1 1 auto;':''" title="Informatie">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <InfoWrapper>
           <div class="info-title">ID</div>
           <div class="info-text">{{ form.id }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Status</div>
           <div class="info-text"><span :style="`color: white;background-color: ${form.active?'#0CAB7C':'#E30D0F'}`"
                                        className="status-text">{{
             form.active ? 'Actief' : 'Inactief'
            }}</span></div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Revisie ID</div>
           <div class="info-text">{{ form.parentID ? form.parentID : 'Geen revisie beschikbaar' }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum aangemaakt</div>
           <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum geupdate</div>
           <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
         </a-col>
        </a-row>
       </sdCards>
       <sdCards v-if="$can('update', 'product')" style="flex: 1 1 auto;" title="Acties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchProduct && selectedUpdateButton === 12"
                     :loading="loadingPatchProduct && selectedUpdateButton ===12"
                     :type="form.active? 'danger' : 'primary'"
                     class="btn-signin w-100"
                     size="large"
                     @click.prevent="activateProduct">
            <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
            <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
            {{ form.active ? 'Deactiveren' : 'Activeren' }}
           </a-button>
          </div>
         </a-col>
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingSyncTariffForProduct" :loading="loadingSyncTariffForProduct"
                     class="btn-signin w-100"
                     size="large"
                     type="primary"
                     @click.prevent="syncShopProducts">
            <sdFeatherIcons class="mr-2" size="16" type="refresh-cw"/>
            Producten syncen
           </a-button>
          </div>
         </a-col>
        </a-row>
       </sdCards>
      </a-col>
     </a-row>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Product omschrijving">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">
         <a-row :gutter="30">
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Extra informatie">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.description"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Unique Selling Point">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.uspText"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Eigenschappen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct3">
         <a-row :gutter="30">
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Soho"
                        name="isSoho">
            <a-select v-model:value="form.isSoho" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="isSoho">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zichtbaarheid op basis van BAG resultaat"
                        name="energyBagLimitationType">
            <a-select v-model:value="form.energyBagLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in bagLimitationOptions" :key="index"
                              :value="item.value" name="energyBagLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Verloop type voor offertes"
                        name="expiryType">
            <a-select v-model:value="form.expiryType" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in productExpiryTypeOptions" :key="index"
                              :value="item.value" name="expiryType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.expiryType === 'FIXEDDAYS'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte verloopdatum"
                        name="expiryDays">
            <a-input v-model:value="form.expiryDays"
                     :addon-after="!form.expiryDays || form.expiryDays === '0' || form.expiryDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Startdatum verplicht?"
                        name="energyHasStartDate">
            <a-select v-model:value="form.energyHasStartDate" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasStartDate">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Minimale leeftijd"
                        name="minAge">
            <a-input v-model:value="form.minAge" :disabled="!$can('update', 'product')" addon-after="jaar"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Maximale leeftijd"
                        name="maxAge">
            <a-input v-model:value="form.maxAge" :disabled="!$can('update', 'product')" addon-after="jaar"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Uitgebreide tarief condities"
                        name="hasExtendedEnergyTariffLimitation">
            <a-select v-model:value="form.hasExtendedEnergyTariffLimitation"
                      :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in defaultOptions"
                              :key="index"
                              :value="item.value" name="hasExtendedEnergyTariffLimitation">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender overrulen"
                        name="overrideSender">
            <a-select v-model:value="form.overrideSender" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.overrideSenderID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="overrideSender">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.overrideSender && form.type !== null && form.type === 'ENERGY'" :sm="8" :xs="24"
                 class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender"
                        name="overrideSenderID">
            <a-select v-model:value="form.overrideSenderID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in senders" :key="index"
                              :value="item.id" name="senderID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 3 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 3 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Templates">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct4">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Pdf Template"
                        name="pdfTemplateID">
            <a-select v-model:value="form.pdfTemplateID" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.PDF" :key="index"
                              :value="item.id" name="pdfTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email offerte template"
                        name="offerEmailTemplateID">
            <a-select v-model:value="form.offerEmailTemplateID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="offerEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bevestigingsmail versturen"
                        name="hasConfirmationEmail">
            <a-select v-model:value="form.hasConfirmationEmail" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.confirmationEmailTemplateID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasConfirmationEmail" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email bevestiging template"
                        name="confirmationEmailTemplateID">
            <a-select v-model:value="form.confirmationEmailTemplateID"
                      :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="confirmationEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Scripts">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct12">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Voicelog?"
                        name="hasScript">
            <a-select v-model:value="form.hasScript" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.scriptID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasScript">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasScript" :sm="16" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Selecteer een voicelog"
                        name="scriptID">
            <a-select v-model:value="form.scriptID"
                      :allowClear="true"
                      :disabled="!$can('update', 'product') || !form.hasScript" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in scripts" :key="index"
                              :value="item.id" name="scriptID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div v-if="form.type !== null && form.type === 'ENERGY'" class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Pdf teksten">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct5">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bedenktijd?"
                        name="hasCoolOffPeriod">
            <a-select v-model:value="form.hasCoolOffPeriod" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.coolOffPeriodText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasCoolOffPeriod ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Bedenktijd tekst"
            name="coolOffPeriodText"
           >
            <a-input
             v-model:value="form.coolOffPeriodText"
             :disabled="!$can('update', 'product') || !form.hasCoolOffPeriod"
             name="coolOffPeriodText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Boetevergoeding?"
                        name="energyHasFineCompensation">
            <a-select v-model:value="form.energyHasFineCompensation" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large"
                      @change="form.energyFineCompensationText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasFineCompensation">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.energyHasFineCompensation ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Boetevergoeding tekst"
            name="energyFineCompensationText"
           >
            <a-input
             v-model:value="form.energyFineCompensationText"
             :disabled="!$can('update', 'product') || !form.energyHasFineCompensation"
             name="energyFineCompensationText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Boete bij beëindiging?"
                        name="hasFineOnTermination">
            <a-select v-model:value="form.hasFineOnTermination" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.fineOnTerminationText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasFineOnTermination">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasFineOnTermination ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Boete bij beëindiging tekst"
            name="fineOnTerminationText"
           >
            <a-input
             v-model:value="form.fineOnTerminationText"
             :disabled="!$can('update', 'product') || !form.hasFineOnTermination"
             name="fineOnTerminationText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Incentive?"
                        name="hasIncentive">
            <a-select v-model:value="form.hasIncentive" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.incentiveText = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasIncentive">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="16" :xs="24" class="mb-25">
           <a-form-item
            :rules="form.hasIncentive ? {required: true,message: 'Dit veld is verplicht'}: ''"
            label="Incentive tekst"
            name="incentiveText"
           >
            <a-input
             v-model:value="form.incentiveText"
             :disabled="!$can('update', 'product') || !form.hasIncentive"
             name="incentiveText"
             type="text"
            />
           </a-form-item>
          </a-col>
          <a-col :xs="24" class="mb-25">
           <a-form-item
            :rules="{required: true,message: 'Dit veld is verplicht'}"
            label="Tarief Soort"
            name="energyTariffTypeText"
           >
            <a-input
             v-model:value="form.energyTariffTypeText"
             :disabled="!$can('update', 'product')"
             name="energyTariffTypeText"
             type="text"
            />
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div v-if="form.type !== null && form.type === 'ENERGY'" class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Switch venster">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct9">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Minimum in dagen"
                        name="energySwitchWindowMinDays">
            <a-input v-model:value="form.energySwitchWindowMinDays"
                     :addon-after="!form.energySwitchWindowMinDays || form.energySwitchWindowMinDays === '0' || form.energySwitchWindowMinDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Maximum in dagen"
                        name="energySwitchWindowMaxDays">
            <a-input v-model:value="form.energySwitchWindowMaxDays"
                     :addon-after="!form.energySwitchWindowMaxDays || form.energySwitchWindowMaxDays === '0' || form.energySwitchWindowMaxDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div v-if="form?.type === 'ENERGY'" class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="EAN Blocker">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct10">
         <a-row :gutter="30">
          <a-col :sm="12" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bestaande EAN's blokkeren"
                        name="energyHasEanBlocker">
            <a-select v-model:value="form.energyHasEanBlocker" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.energyEanBlockerDays = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="energyHasEanBlocker">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="12" :xs="24" class="mb-25">
           <a-form-item :rules="form.energyHasEanBlocker ? {required: true,message: 'Dit veld is verplicht'}: ''"
                        label="Aantal dagen blokkeren"
                        name="energyEanBlockerDays">
            <a-input v-model:value="form.energyEanBlockerDays"
                     :addon-after="!form.energyEanBlockerDays || form.energyEanBlockerDays === '0' || form.energyEanBlockerDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')||!form.energyHasEanBlocker"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div v-if="form?.type === 'ENERGY'" class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Verbruik limitaties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct11">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Limitatie type"
                        name="energyUsageLimitationType">
            <a-select v-model:value="form.energyUsageLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="cleanLimitations">
             <a-select-option v-for="(item,index) in ProductEnergyUsageLimitationType"
                              :key="index"
                              :value="item.value" name="energyUsageLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row v-if="form.energyUsageLimitationType !== 'NONE'" :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Minimale stroom verbruik" name="energyMinElectricityUsage">
            <a-input v-model:value="form.energyMinElectricityUsage" :disabled="!$can('update', 'product')"
                     addon-after="kWh"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Maximale stroom verbruik" name="energyMaxElectricityUsage">
            <a-input v-model:value="form.energyMaxElectricityUsage" :disabled="!$can('update', 'product')"
                     addon-after="kWh"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row v-if="form.energyUsageLimitationType !== 'NONE'" :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Minimale gas verbruik" name="energyMinGasUsage">
            <a-input v-model:value="form.energyMinGasUsage" :disabled="!$can('update', 'product')"
                     addon-after="m3"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item label="Maximale gas verbruik" name="energyMaxGasUsage">
            <a-input v-model:value="form.energyMaxGasUsage" :disabled="!$can('update', 'product')"
                     addon-after="m3"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="2">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Productbeheer
            </span>
     </template>
     <CardFrame
      :style="{ width: '100%' }"
      size="default"
     >
      <template #title>
       <slot name="title">Producten
       </slot>
      </template>
      <template v-slot:extra>
       <AddShopSubProduct/>
      </template>
      <slot>
       <ShopSubProductsTable/>
      </slot>
     </CardFrame>
    </Child>
    <Child :key="3">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="message-square"/>
              Vragen en accoderingen
            </span>
     </template>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Productvragen">
        <sdModal :onCancel="handleProductFlowQuestionsModalCancel" :visible="visibleProductFlowQuestions"
                 title="Productvraag toevoegen" type="primary">
         <ModalMain>
          <div class="create-account-form">
           <a-form :model="{selectedAddProductFlowQuestion:selectedAddProductFlowQuestion}" layout="vertical"
                   @finish="handleAddQuestion">
            <a-row :gutter="30">
             <a-col :xs="24" class="mb-25">
              <a-form-item :name="['selectedAddProductFlowQuestion',index]" label="Selecteer een vraag">
               <a-select v-model:value="selectedAddProductFlowQuestion" :allowClear="true"
                         class="sDash_fullwidth-select" size="large">
                <a-select-option
                 v-for="(item,index) in questionOptions"
                 :key="index"
                 :name="['selectedAddProductFlowQuestion', index]" :value="item.id">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :xs="24"
                    style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
              <sdButton class="ml-4" size="small" type="primary">
               <sdFeatherIcons size="14" type="plus"/>
               Toevoegen
              </sdButton>
             </a-col>
            </a-row>
           </a-form>
          </div>
         </ModalMain>
        </sdModal>
        <a-skeleton v-if="loadingFlowQuestions" active class="mb-2"/>
        <a-form v-else :model="{productFlowQuestions:productFlowQuestions}" layout="vertical" @finish="UpdateProduct6">
         <a-row v-if="productFlowQuestions.length > 0" :gutter="30">
          <template v-for="(item, index) in productFlowQuestions" :key="index">
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item label="Geselecteerde vraag">
             <a-select v-model:value="item.flowQuestion.id" :allowClear="true" :disabled="true"
                       class="sDash_fullwidth-select" size="large">
              <a-select-option v-for="(item,index) in questionOptions"
                               :key="index" :value="item.id" style="color:#000000">
               {{ item.name }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'displayName']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Weergavenaam">
             <a-input v-model:value="item.displayName" name="displayName" type="text"/>
            </a-form-item>
           </a-col>
           <a-col :sm="4" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'isRequired']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Verplicht">
             <a-select v-model:value="item.isRequired" :allowClear="true"
                       :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                       size="large">
              <a-select-option v-for="(item,index) in defaultOptions"
                               :key="index"
                               :name="['productFlowQuestions', index, 'isRequired']" :value="item.value">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :xs="4" style="vertical-align: middle;display: flex;justify-content: end;">
            <a-form-item label=" ">
             <sdButton class="ml-4" size="small" type="danger"
                       @click.prevent="handleDeleteProductFlowQuestions(index)">
              <sdFeatherIcons size="14" type="trash"/>
             </sdButton>
            </a-form-item>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product') && questionOptions.length > 0" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddProductFlowQuestions">
             Toevoegen
            </a-button>
            <a-button v-if="productFlowQuestions.length > 0"
                      :disabled="loadingPatchProduct && selectedUpdateButton === 6"
                      :loading="loadingPatchProduct && selectedUpdateButton === 6" class="btn-signin"
                      html-type="submit"
                      size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Accoderingen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form :model="{customerApprovals:form.customerApprovals}" layout="vertical" @finish="UpdateProduct7">
         <a-row v-if="form.customerApprovals && form.customerApprovals.length > 0" :gutter="30">
          <template v-for="(item, index) in form.customerApprovals" :key="index">
           <a-col :sm="20" :xs="24" class="mb-25">
            <a-form-item :name="['customerApprovals', index]"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <a-input v-model:value="form.customerApprovals[index]" :name="['customerApprovals', index]"
                      type="text"/>
            </a-form-item>
           </a-col>
           <a-col :xs="4"
                  style="vertical-align: middle;display: flex;justify-content: end;">
            <sdButton class="ml-4" size="small" type="danger"
                      @click.prevent="handleDeleteCustomerApprovals(index)">
             <sdFeatherIcons size="14" type="trash"/>
            </sdButton>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddCustomerApprovals">
             Toevoegen
            </a-button>
            <sdButton
             v-if="form.customerApprovals && form.customerApprovals.length > 0 && $can('update', 'product')"
             class="btn-signin" type="primary"> Opslaan
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="4">
     <template #tab>
            <span @click="loadProductFilesTab">
              <sdFeatherIcons size="18" type="archive"/>
              Bestanden
            </span>
     </template>
     <div class="user-info-form">
      <sdCards title="Product bestanden">
       <FilesListTable/>
       <a-row class="mt-6">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
           Toevoegen
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
   </TabBasic>
   <TabBasic
    v-if="form.type !== null && form.type === 'SOLAR'"
    v-model:activeKey="activeKey"
    color="#ffffff"
    tabPosition="top"
    @tabClick="handleTabSolar"
   >
    <Child :key="1">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Overview
            </span>
     </template>
     <a-row :gutter="15" type="flex">
      <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
       <Upload style="flex: 1 1 auto;">
        <div class="user-info-form">
         <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
           <a-skeleton v-if="loadingProduct" active/>
           <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct1">
            <a-row :gutter="30">
             <a-col :sm="12" :xs="12" class="mb-25">
              <a-form-item label="Product foto" name=""
                           rules="">
               <figure class="photo-upload align-center-v">
                <img
                 v-if="form.imageFileURL && form.imageFileURL !== ''"
                 :src="form.imageFileURL"
                 alt=""
                 style="max-width: 200px;border-radius:0 !important"
                />
                <img
                 v-else
                 :src="require('@/static/img/avatar/profileImage.png')"
                 alt=""
                 style="max-width: 200px;border-radius:0 !important"
                />
                <figcaption>
                 <a-upload
                  :action="`${fileUrl}products/${form.id}/file`"
                  :disabled="!$can('update', 'product')"
                  :headers="fileUploadHeaders"
                  :multiple="false"
                  name="file"
                  @change="handleUpload">
                  <a class="btn-upload" to="#">
                   <sdFeatherIcons size="16" type="camera"/>
                  </a>
                 </a-upload>
                </figcaption>
               </figure>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="12" class="mb-25">
              <a-form-item label="Banner foto">
               <figure class="photo-upload align-center-v" style="border-radius:0 !important">
                <img
                 v-if="form.secondaryImageFileURL && form.secondaryImageFileURL !== ''"
                 :src="form.secondaryImageFileURL"
                 alt=""
                 style="max-width: 200px;border-radius:0 !important"
                />
                <img
                 v-else
                 :src="require('@/static/img/avatar/profileImage.png')"
                 alt=""
                 style="max-width: 120px;border-radius:0 !important"
                />
                <figcaption>
                 <a-upload
                  :action="`${fileUrl}products/${form.id}/secondaryfile`"
                  :disabled="!$can('update', 'product')"
                  :headers="fileUploadHeaders"
                  :multiple="false"
                  name="file"
                  @change="handleUpload">
                  <a class="btn-upload" to="#">
                   <sdFeatherIcons size="16" type="camera"/>
                  </a>
                 </a-upload>
                </figcaption>
               </figure>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam"
                           name="name">
               <a-input v-model:value="form.name" :disabled="!$can('update', 'product')" placeholder=""/>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Leverancier"
                           name="supplierID">
               <a-select v-model:value="form.supplierID" :allowClear="true"
                         :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in suppliers" :key="index"
                                 :value="item.id" name="supplierID">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                           name="type">
               <a-select v-model:value="form.type" :allowClear="true" :disabled="!$can('update', 'product')"
                         class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in productTypes" :key="index"
                                 :value="item.key" name="type">
                 {{ item.value }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Tonen in offerte flow?"
                           name="hasEsignatureSigning">
               <a-select v-model:value="form.hasEsignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasEsignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                           label=" Tonen in overeenkomsten flow"
                           name="hasSignatureSigning">
               <a-select v-model:value="form.hasSignatureSigning" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="hasSignatureSigning">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Is een machtiging?"
                           name="isProxy">
               <a-select v-model:value="form.isProxy" :allowClear="true"
                         :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                         size="large">
                <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                                 :value="item.value" name="isProxy">
                 {{ item.key }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig van"
                           name="validFrom"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validFrom" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
             <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Geldig tot"
                           name="validTill"
                           style="min-height: 48px;margin-bottom: 25px;">
               <DatePickerWrapper>
                <a-date-picker v-model:value="form.validTill" :format="dateFormat" class="w-100"/>
               </DatePickerWrapper>
              </a-form-item>
             </a-col>
            </a-row>
            <a-row>
             <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
               <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 1 && loadingPatchProduct"
                         :loading="selectedUpdateButton === 1 && loadingPatchProduct" class="btn-signin"
                         html-type="submit" size="large"
                         type="primary"> Opslaan
               </a-button>
              </div>
             </a-col>
            </a-row>
           </a-form>
          </sdCards>
         </BasicFormWrapper>
        </div>
       </Upload>
      </a-col>
      <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
       <sdCards :style="!$can('update', 'product')?'flex: 1 1 auto;':''" title="Informatie">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <InfoWrapper>
           <div class="info-title">ID</div>
           <div class="info-text">{{ form.id }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Status</div>
           <div class="info-text">
            <span :style="`color: white;background-color: ${form.active?'#0CAB7C':'#E30D0F'}`" className="status-text">{{
              form.active ? 'Actief' : 'Inactief'
             }}</span>
           </div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title">Revisie ID</div>
           <div class="info-text">{{ form.parentID ? form.parentID : 'Geen revisie beschikbaar' }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum aangemaakt</div>
           <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
          <InfoWrapper>
           <div class="info-title"> Datum geupdate</div>
           <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
          </InfoWrapper>
         </a-col>
        </a-row>
       </sdCards>
       <sdCards v-if="$can('update', 'product')" style="flex: 1 1 auto;" title="Acties">
        <a-skeleton v-if="loadingProduct" active/>
        <a-row v-else :gutter="30">
         <a-col :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchProduct && selectedUpdateButton === 12"
                     :loading="loadingPatchProduct && selectedUpdateButton ===12"
                     :type="form.active? 'danger' : 'primary'"
                     class="btn-signin w-100"
                     size="large"
                     @click.prevent="activateProduct">
            <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
            <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
            {{ form.active ? 'Deactiveren' : 'Activeren' }}
           </a-button>
          </div>
         </a-col>
         <a-col v-if="!form.isProxy && form.type !== null && form.type === 'ENERGY'" :xs="24" class="mb-25">
          <div class="sDash_form-action">
           <a-button :disabled="loadingSyncTariffForProduct" :loading="loadingSyncTariffForProduct"
                     class="btn-signin w-100"
                     size="large"
                     type="primary"
                     @click.prevent="syncTariff">
            <sdFeatherIcons class="mr-2" size="16" type="refresh-cw"/>
            Tarieven syncen
           </a-button>
          </div>
         </a-col>
        </a-row>
       </sdCards>
      </a-col>
     </a-row>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Product omschrijving">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct2">
         <a-row :gutter="30">
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Extra informatie">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.description"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" class="mb-25">
           <a-form-item label="Unique Selling Point">
            <div class="sDash_editor">
             <ckeditor
              v-model="form.uspText"
              :config="editorConfig"
              :disabled="!$can('update', 'product')"
              :editor="editor"
             ></ckeditor>
            </div>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 2 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 2 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Eigenschappen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct3">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}"
                        label="Zichtbaarheid op basis van BAG resultaat"
                        name="energyBagLimitationType">
            <a-select v-model:value="form.energyBagLimitationType" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in bagLimitationOptions" :key="index"
                              :value="item.value" name="energyBagLimitationType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Verloop type voor offertes"
                        name="expiryType">
            <a-select v-model:value="form.expiryType" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in productExpiryTypeOptions" :key="index"
                              :value="item.value" name="expiryType">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.expiryType === 'FIXEDDAYS'" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte verloopdatum"
                        name="expiryDays">
            <a-input v-model:value="form.expiryDays"
                     :addon-after="!form.expiryDays || form.expiryDays === '0' || form.expiryDays === '1'  ? 'dag' : 'dagen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Minimaal aantal panelen"
                        name="solarMinPanelCount">
            <a-input v-model:value="form.solarMinPanelCount"
                     :addon-after="!form.solarMinPanelCount || form.solarMinPanelCount === '0' || form.solarMinPanelCount === '1'  ? 'paneel' : 'panelen'"
                     :disabled="!$can('update', 'product')"
                     onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                     onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                     placeholder=""
                     type="number"/>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender overrulen"
                        name="overrideSender">
            <a-select v-model:value="form.overrideSender" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.overrideSenderID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="overrideSender">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.overrideSender" :sm="8" :xs="24"
                 class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Afzender"
                        name="overrideSenderID">
            <a-select v-model:value="form.overrideSenderID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in senders" :key="index"
                              :value="item.id" name="senderID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" :disabled="selectedUpdateButton === 3 && loadingPatchProduct"
                      :loading="selectedUpdateButton === 3 && loadingPatchProduct" class="btn-signin"
                      html-type="submit" size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Templates">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct4">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Pdf Template"
                        name="pdfTemplateID">
            <a-select v-model:value="form.pdfTemplateID" :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.PDF" :key="index"
                              :value="item.id" name="pdfTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email offerte template"
                        name="offerEmailTemplateID">
            <a-select v-model:value="form.offerEmailTemplateID" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="offerEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Bevestigingsmail versturen"
                        name="hasConfirmationEmail">
            <a-select v-model:value="form.hasConfirmationEmail" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large" @change="form.confirmationEmailTemplateID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                              name="hasCoolOffPeriod">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasConfirmationEmail" :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Email bevestiging template"
                        name="confirmationEmailTemplateID">
            <a-select v-model:value="form.confirmationEmailTemplateID"
                      :allowClear="true" :disabled="!$can('update', 'product')"
                      class="sDash_fullwidth-select" size="large">
             <a-select-option v-for="(item,index) in productCommunicationsFilters?.EMAIL"
                              :key="index" :value="item.id"
                              name="confirmationEmailTemplateID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Scripts">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form v-else :model="form" layout="vertical" @finish="UpdateProduct12">
         <a-row :gutter="30">
          <a-col :sm="8" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Voicelog?"
                        name="hasScript">
            <a-select v-model:value="form.hasScript" :allowClear="true"
                      :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                      size="large"
                      @change="form.scriptID = null">
             <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                              :value="item.value" name="hasScript">
              {{ item.key }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
          <a-col v-if="form.hasScript" :sm="16" :xs="24" class="mb-25">
           <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Selecteer een voicelog"
                        name="scriptID">
            <a-select v-model:value="form.scriptID"
                      :allowClear="true"
                      :disabled="!$can('update', 'product') || !form.hasScript" class="sDash_fullwidth-select"
                      size="large">
             <a-select-option v-for="(item,index) in scripts" :key="index"
                              :value="item.id" name="scriptID">
              {{ item.name }}
             </a-select-option>
            </a-select>
           </a-form-item>
          </a-col>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <sdButton v-if="$can('update', 'product')" class="btn-signin" type="primary"> Opslaan</sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="2">
     <template #tab>
            <span>
              <sdFeatherIcons size="18" type="package"/>
              Productbeheer
            </span>
     </template>
     <CardFrame
      :style="{ width: '100%' }"
      size="default"
     >
      <template #title>
       <slot name="title">Producten
       </slot>
      </template>
      <template v-slot:extra>
       <AddSolarSubProduct/>
      </template>
      <slot>
       <SolarProductsTable/>
      </slot>
     </CardFrame>
    </Child>
    <Child :key="3">
     <template #tab>
            <span @click="loadQuestionsTab">
              <sdFeatherIcons size="18" type="message-square"/>
              Vragen en accoderingen
            </span>
     </template>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Productvragen">
        <sdModal :onCancel="handleProductFlowQuestionsModalCancel" :visible="visibleProductFlowQuestions"
                 title="Productvraag toevoegen" type="primary">
         <ModalMain>
          <div class="create-account-form">
           <a-form :model="{selectedAddProductFlowQuestion:selectedAddProductFlowQuestion}" layout="vertical"
                   @finish="handleAddQuestion">
            <a-row :gutter="30">
             <a-col :xs="24" class="mb-25">
              <a-form-item :name="['selectedAddProductFlowQuestion',index]" label="Selecteer een vraag">
               <a-select v-model:value="selectedAddProductFlowQuestion" :allowClear="true"
                         class="sDash_fullwidth-select" size="large">
                <a-select-option
                 v-for="(item,index) in questionOptions"
                 :key="index"
                 :name="['selectedAddProductFlowQuestion', index]" :value="item.id">
                 {{ item.name }}
                </a-select-option>
               </a-select>
              </a-form-item>
             </a-col>
             <a-col :xs="24"
                    style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
              <sdButton class="ml-4" size="small" type="primary">
               <sdFeatherIcons size="14" type="plus"/>
               Toevoegen
              </sdButton>
             </a-col>
            </a-row>
           </a-form>
          </div>
         </ModalMain>
        </sdModal>
        <a-skeleton v-if="loadingFlowQuestions" active class="mb-2"/>
        <a-form v-else :model="{productFlowQuestions:productFlowQuestions}" layout="vertical" @finish="UpdateProduct6">
         <a-row v-if="productFlowQuestions.length > 0" :gutter="30">
          <template v-for="(item, index) in productFlowQuestions" :key="index">
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item label="Geselecteerde vraag">
             <a-select v-model:value="item.flowQuestion.id" :allowClear="true" :disabled="true"
                       class="sDash_fullwidth-select" size="large">
              <a-select-option v-for="(item,index) in questionOptions"
                               :key="index" :value="item.id" style="color:#000000">
               {{ item.name }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :sm="8" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'displayName']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Weergavenaam">
             <a-input v-model:value="item.displayName" name="displayName" type="text"/>
            </a-form-item>
           </a-col>
           <a-col :sm="4" :xs="24" class="mb-25">
            <a-form-item :name="['productFlowQuestions', index, 'isRequired']"
                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                         label="Verplicht">
             <a-select v-model:value="item.isRequired" :allowClear="true"
                       :disabled="!$can('update', 'product')" class="sDash_fullwidth-select"
                       size="large">
              <a-select-option v-for="(item,index) in defaultOptions"
                               :key="index"
                               :name="['productFlowQuestions', index, 'isRequired']" :value="item.value">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :xs="4" style="vertical-align: middle;display: flex;justify-content: end;">
            <a-form-item label=" ">
             <sdButton class="ml-4" size="small" type="danger"
                       @click.prevent="handleDeleteProductFlowQuestions(index)">
              <sdFeatherIcons size="14" type="trash"/>
             </sdButton>
            </a-form-item>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product') && questionOptions.length > 0" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddProductFlowQuestions">
             Toevoegen
            </a-button>
            <a-button v-if="productFlowQuestions.length > 0"
                      :disabled="loadingPatchProduct && selectedUpdateButton === 6"
                      :loading="loadingPatchProduct && selectedUpdateButton === 6" class="btn-signin"
                      html-type="submit"
                      size="large"
                      type="primary"> Opslaan
            </a-button>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
     <div class="user-info-form">
      <BasicFormWrapper class="mb-25">
       <sdCards title="Accoderingen">
        <a-skeleton v-if="loadingProduct" active/>
        <a-form :model="{customerApprovals:form.customerApprovals}" layout="vertical" @finish="UpdateProduct7">
         <a-row v-if="form.customerApprovals && form.customerApprovals.length > 0" :gutter="30">
          <template v-for="(item, index) in form.customerApprovals" :key="index">
           <a-col :sm="20" :xs="24" class="mb-25">
            <a-form-item :name="['customerApprovals', index]"
                         :rules="{required: true,message: 'Dit veld is verplicht'}">
             <a-input v-model:value="form.customerApprovals[index]" :name="['customerApprovals', index]"
                      type="text"/>
            </a-form-item>
           </a-col>
           <a-col :xs="4"
                  style="vertical-align: middle;display: flex;justify-content: end;">
            <sdButton class="ml-4" size="small" type="danger"
                      @click.prevent="handleDeleteCustomerApprovals(index)">
             <sdFeatherIcons size="14" type="trash"/>
            </sdButton>
           </a-col>
          </template>
         </a-row>
         <a-row>
          <a-col :sm="12" :xs="24">
           <div class="sDash_form-action">
            <a-button v-if="$can('update', 'product')" class="btn-signin"
                      size="large" type="primary" @click.prevent="handleAddCustomerApprovals">
             Toevoegen
            </a-button>
            <sdButton
             v-if="form.customerApprovals && form.customerApprovals.length > 0 && $can('update', 'product')"
             class="btn-signin" type="primary"> Opslaan
            </sdButton>
           </div>
          </a-col>
         </a-row>
        </a-form>
       </sdCards>
      </BasicFormWrapper>
     </div>
    </Child>
    <Child :key="4">
     <template #tab>
            <span @click="loadProductFilesTab">
              <sdFeatherIcons size="18" type="archive"/>
              Bestanden
            </span>
     </template>
     <div class="user-info-form">
      <sdCards title="Product bestanden">
       <FilesListTable/>
       <a-row class="mt-6">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
           Toevoegen
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </div>
    </Child>
   </TabBasic>
   <sdCards>
    <a-row>
     <a-col :sm="12" :xs="24">
      <div class="sDash_form-action">
       <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.go(-1)">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, InfoWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import moment from "moment";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TariffListTable from './component/TariffTable';
import {
 bagLimitationOptions,
 confirmationEmailTemplateOptions,
 contractDurationType,
 customerTypeOptions,
 defaultOptions,
 energyTypeOptions,
 energyUsageLimitationTypeOptions,
 offerEmailTemplateOptions,
 pdfTemplateOptions,
 ProductEnergyUsageLimitationType,
 productExpiryTypeOptions,
 productTypeOptions,
 retentionTypeOptions,
} from "../../utility/enums";
import {getItem} from "../../utility/localStorageControl";
import {getApiURL} from "../../config/dataService/dataService";
import FilesListTable from "./component/FilesTable";
import {Child, TabBasic} from "../../components/tabs/Style";
import SolarSimpleTariffTable from "@/view/product/component/SolarSimpleTariffTable";
import {integerValidator} from "@/utility/validators";
import SolarSimpleSubProduct from "@/view/product/component/SolarSimpleSubProduct";
import ShopSubProductsTable from "@/view/product/component/ShopSubProductsTable";
import AddShopSubProduct from "@/view/product/component/AddShopSubProduct";
import {CardFrame} from "@/components/cards/frame/style";
import SolarProductsTable from "@/view/product/component/SolarProductsTable";
import AddSolarSubProduct from "@/view/product/component/AddSolarSubProduct";

export default defineComponent({
 name: 'ProductEdit',
 components: {
  BasicFormWrapper,
  Main,
  Upload,
  InfoWrapper,
  TariffListTable,
  FilesListTable,
  TabBasic,
  Child,
  SolarSimpleTariffTable,
  SolarSimpleSubProduct,
  ShopSubProductsTable,
  AddShopSubProduct,
  CardFrame,
  SolarProductsTable,
  AddSolarSubProduct,
 },
 data() {
  return {
   editor: ClassicEditor,
   editorConfig: {
    height: 200,
   },
   fileUrl: getApiURL(),
   dateFormat: 'DD-MM-YYYY',
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const suppliers = computed(() => state.supplier.suppliers);
  const form = reactive(computed(() => state.product.product));
  const errorProduct = computed(() => state.product.errorProduct);
  const loadingProduct = computed(() => state.product.loadingProduct);
  const loadingPatchProduct = computed(() => state.product.loadingPatchProduct);
  const productFlowQuestions = computed(() => state.product.productFlowQuestions);
  const loadingFlowQuestions = computed(() => state.product.loadingFlowQuestions);
  const productTypes = computed(() => state.product.types);
  const loadingSyncTariffForProduct = computed(() => state.product.loadingSyncTariffForProduct);
  const productCommunicationsFilters = computed(() => state.filter.productCommunicationsFilters);
  const senders = computed(() => state.sender.senders);
  const questionOptions = computed(() => state.question.questions);
  const selectedAddProductFlowQuestion = ref(null);
  const scripts = computed(() => state.script.scripts);
  const addOrShowTariff = ref('ADD');
  const addOrShowSolarSimpleTariff = ref('ADD');
  const addOrShowSubProduct = ref('ADD');
  const solarSimpleSubProductRef = ref();
  let visibleProductFlowQuestions = ref(false);
  let selectedUpdateButton = ref(0);
  let addTariffModalVisible = ref(false);
  let addTariffSolarSimpleModalVisible = ref(false);
  let addSubProductModalVisible = ref(false);
  const showFileUploadRef = ref(false);
  const fileList = ref([]);
  let addTariff = reactive({
   id: null,
   productID: null,
   tariffFixedEnergy: null,
   tariffFixedGas: null,
   tariffGas1: null,
   tariffGas2: null,
   tariffHigh: null,
   tariffLow: null,
   tariffReturnHigh: null,
   tariffReturnLow: null,
   tariffReturnSingle: null,
   tariffSingle: null,
   validFrom: null,
   validTill: null,
   active: false,
   startDateFrom: null,
   startDateTill: null,
  });

  let addSolarSimpleTariff = reactive({
   id: null,
   productID: null,
   active: null,
   validFrom: null,
   validTill: null,
   panelCountFrom: null,
   panelCountTill: null,
   price: null
  });

  let addSubProduct = reactive({
   id: null,
   productID: null,
   active: null,
   description: '',
   imageFile: null,
   name: null,
  });

  const UpdateProduct1 = () => {
   selectedUpdateButton.value = 1;
   dispatch('patchProduct', {
    id: params.id,
    name: form.value.name,
    supplierID: form.value.supplierID,
    type: form.value.type,
    energyType: form.value.energyType,
    retentionType: form.value.retentionType,
    contractDurationMonths: form.value.contractDurationMonths,
    contractDurationDate: form.value.contractDurationDate ? moment(form.value.contractDurationDate).format('YYYY-MM-DD') : null,
    energyAllowSingleFuelElectricity: form.value.energyAllowSingleFuelElectricity,
    energyAllowSingleFuelGas: form.value.energyAllowSingleFuelGas,
    allowedCustomerType: form.value.allowedCustomerType,
    isProxy: form.value.isProxy,
    hasEsignatureSigning: form.value.hasEsignatureSigning,
    hasSignatureSigning: form.value.hasSignatureSigning,
    validFrom: form.value.validFrom ? moment(form.value.validFrom).format('YYYY-MM-DD') : null,
    validTill: form.value.validTill ? moment(form.value.validTill).format('YYYY-MM-DD') : null,
    contractDurationType: form.value.contractDurationType,
   });
  };

  const UpdateProduct2 = () => {
   selectedUpdateButton.value = 2;
   dispatch('patchProduct', {
    id: params.id,
    uspText: form.value.uspText,
    description: form.value.description,
   });
  };

  const UpdateProduct3 = () => {
   selectedUpdateButton.value = 3;
   dispatch('patchProduct', {
    id: params.id,
    isSoho: form.value.isSoho,
    energyBagLimitationType: form.value.energyBagLimitationType,
    expiryType: form.value.expiryType,
    expiryDays: form.value.expiryDays,
    energyHasStartDate: form.value.energyHasStartDate,
    minAge: form.value.minAge,
    maxAge: form.value.maxAge,
    hasExtendedEnergyTariffLimitation: form.value.hasExtendedEnergyTariffLimitation,
    overrideSender: form.value.overrideSender,
    overrideSenderID: form.value.overrideSenderID,
    solarPanelPower: parseInt(form.value.solarPanelPower),
    hasFixedPanelCount: form.value.hasFixedPanelCount,
    fixedPanelCount: parseInt(form.value.fixedPanelCount),
    solarMinPanelCount: parseInt(form.value.solarMinPanelCount),
   });
  };

  const UpdateProduct4 = () => {
   selectedUpdateButton.value = 4;
   dispatch('patchProduct', {
    id: params.id,
    offerEmailTemplateID: form.value.offerEmailTemplateID,
    confirmationEmailTemplateID: form.value.confirmationEmailTemplateID,
    pdfTemplateID: form.value.pdfTemplateID,
    hasConfirmationEmail: form.value.hasConfirmationEmail,
   });
  };

  const UpdateProduct5 = () => {
   selectedUpdateButton.value = 5;
   dispatch('patchProduct', {
    id: params.id,
    hasCoolOffPeriod: form.value.hasCoolOffPeriod,
    coolOffPeriodText: form.value.coolOffPeriodText,
    energyHasFineCompensation: form.value.energyHasFineCompensation,
    energyFineCompensationText: form.value.energyFineCompensationText,
    hasFineOnTermination: form.value.hasFineOnTermination,
    fineOnTerminationText: form.value.fineOnTerminationText,
    hasIncentive: form.value.hasIncentive,
    incentiveText: form.value.incentiveText,
    energyTariffTypeText: form.value.energyTariffTypeText,
   });
  };

  const UpdateProduct6 = () => {
   selectedUpdateButton.value = 6;
   dispatch('patchProductFlowQuestion', {
    id: params.id,
    flowQuestions: productFlowQuestions.value,
   });
  };

  const UpdateProduct7 = () => {
   selectedUpdateButton.value = 7;
   if (form.value.customerApprovals === null || form.value.customerApprovals === '') {
    form.value.customerApprovals = [];
   }
   dispatch('patchProduct', {
    id: params.id,
    customerApprovals: form.value.customerApprovals,
   });
  };

  const UpdateProduct9 = () => {
   selectedUpdateButton.value = 9;
   dispatch('patchProduct', {
    id: params.id,
    energySwitchWindowMinDays: form.value.energySwitchWindowMinDays,
    energySwitchWindowMaxDays: form.value.energySwitchWindowMaxDays,
    energySwitchWindowDefaultDays: form.value.energySwitchWindowDefaultDays,
    energyHasFixedStartDay: form.value.energyHasFixedStartDay,
    energyFixedStartDay: form.value.energyFixedStartDay
   });
  };

  const UpdateProduct10 = () => {
   selectedUpdateButton.value = 10;
   dispatch('patchProduct', {
    id: params.id,
    energyHasEanBlocker: form.value.energyHasEanBlocker,
    energyEanBlockerDays: parseInt(form.value.energyEanBlockerDays),
   });
  };

  const UpdateProduct11 = () => {
   selectedUpdateButton.value = 11;
   dispatch('patchProduct', {
    id: params.id,
    energyUsageLimitationType: form.value.energyUsageLimitationType,
    energyMinElectricityUsage: form.value.energyMinElectricityUsage,
    energyMaxElectricityUsage: form.value.energyMaxElectricityUsage,
    energyMinGasUsage: form.value.energyMinGasUsage,
    energyMaxGasUsage: form.value.energyMaxGasUsage,
   });
  };

  const UpdateCompensation = () => {
   selectedUpdateButton.value = 13;
   dispatch('patchProduct', {
    id: params.id,
    compensationAmountElectricity: form.value.compensationAmountElectricity,
    compensationAmountGas: form.value.compensationAmountGas,
   });
  };

  const UpdateProduct12 = () => {
   selectedUpdateButton.value = 12;
   dispatch('patchProduct', {
    id: params.id,
    hasScript: form.value.hasScript,
    scriptID: form.value.scriptID,
   });
  };

  const cleanLimitations = () => {
   if (form.value.energyUsageLimitationType === 'NONE') {
    form.value.energyMinElectricityUsage = null;
    form.value.energyMaxElectricityUsage = null;
    form.value.energyMinGasUsage = null;
    form.value.energyMaxGasUsage = null;
   }
  };

  const activateProduct = async () => {
   selectedUpdateButton.value = 12;
   await dispatch('patchProduct', {
    id: params.id,
    active: form.value.active ? false : true,
   });
   await dispatch('getProduct', params.id);
  };

  const syncTariff = async () => {
   await dispatch('syncTariffForProduct', {id: params.id});
  };

  const syncShopProducts = async () => {
   await dispatch('syncShopProducts', params.id);
  };

  const handleAddCustomerApprovals = () => {
   form.value.customerApprovals.push('');
  };

  const handleDeleteCustomerApprovals = (index) => {
   form.value.customerApprovals.splice(index, 1);
  };

  const handleAddProductFlowQuestions = () => {
   visibleProductFlowQuestions.value = true;
  };

  const handleDeleteProductFlowQuestions = (index) => {
   productFlowQuestions.value.splice(index, 1);
  };

  const handleProductFlowQuestionsModalCancel = () => {
   visibleProductFlowQuestions.value = false;
  };

  const clearTariff = () => {
   addTariff.id = null;
   addTariff.productID = null;
   addTariff.tariffFixedEnergy = null;
   addTariff.tariffFixedGas = null;
   addTariff.tariffGas1 = null;
   addTariff.tariffGas2 = null;
   addTariff.tariffHigh = null;
   addTariff.tariffLow = null;
   addTariff.tariffReturnHigh = null;
   addTariff.tariffReturnLow = null;
   addTariff.tariffReturnSingle = null;
   addTariff.tariffSingle = null;
   addTariff.validFrom = null;
   addTariff.validTill = null;
   addTariff.active = false;
   addTariff.startDateFrom = null;
   addTariff.startDateTill = null;
  };

  const clearSolarSimpleTariff = () => {
   addSolarSimpleTariff.id = null;
   addSolarSimpleTariff.productID = null;
   addSolarSimpleTariff.active = null;
   addSolarSimpleTariff.validFrom = null;
   addSolarSimpleTariff.validTill = null;
   addSolarSimpleTariff.panelCountFrom = null;
   addSolarSimpleTariff.panelCountTill = null;
   addSolarSimpleTariff.price = null;
  };

  const clearSubProduct = () => {
   addSubProduct.id = null;
   addSubProduct.productID = null;
   addSubProduct.active = null;
   addSubProduct.description = '';
   addSubProduct.imageFile = null;
   addSubProduct.imageFileURL = null;
   addSubProduct.name = null;
  }

  const handleAddSubProductModalVisible = async () => {
   await clearSubProduct();
   addOrShowSubProduct.value = 'ADD';
   addSubProductModalVisible.value = true;
  };

  const handleAddTariffSolarSimpleModalVisible = async () => {
   await clearSolarSimpleTariff();
   addOrShowSolarSimpleTariff.value = 'ADD';
   addTariffSolarSimpleModalVisible.value = true;
  };

  const handleAddTariffModalVisible = async () => {
   await clearTariff();
   addOrShowTariff.value = 'ADD';
   addTariffModalVisible.value = true;
  };

  const handleAddTariffModalCancel = async () => {
   await clearTariff();
   addTariffModalVisible.value = false;
  };

  const handleAddTariffSolarSimpleModalCancel = async () => {
   await clearTariff();
   addTariffSolarSimpleModalVisible.value = false;
  };

  const handleAddSubProductModalCancel = async () => {
   await clearSubProduct();
   addSubProductModalVisible.value = false;
  };


  const handleAddTariff = async () => {
   if (addOrShowTariff.value === 'ADD') {
    const data = addTariff;
    data.productID = params.id;
    await dispatch('createTariff', data);
    addTariffModalVisible.value = false;
    clearTariff();
    return;
   }
   if (addOrShowTariff.value === 'EDIT') {
    await dispatch('patchTariff', {
     id: addTariff.id,
     active: addTariff.active,
     productID: addTariff.productID,
     validFrom: addTariff.validFrom,
     validTill: addTariff.validTill,
    });
    addTariffModalVisible.value = false;
    clearTariff();
   }
  };

  const handleAddSolarSimpleTariff = async () => {
   if (addOrShowSolarSimpleTariff.value === 'ADD') {
    const data = addSolarSimpleTariff;
    data.productID = params.id;
    await dispatch('createSolarSimpleTariff', data);
    addTariffSolarSimpleModalVisible.value = false;
    clearSolarSimpleTariff();
    return;
   }
   if (addOrShowSolarSimpleTariff.value === 'EDIT') {
    await dispatch('patchSolarSimpleTariff', {
     id: addSolarSimpleTariff.id,
     active: addSolarSimpleTariff.active,
     productID: addSolarSimpleTariff.productID,
     validFrom: moment(addSolarSimpleTariff.validFrom, 'DD-MM-YYYY').format('YYYY-MM-DD'),
     validTill: moment(addSolarSimpleTariff.validTill, 'DD-MM-YYYY').format('YYYY-MM-DD'),
     panelCountFrom: addSolarSimpleTariff.panelCountFrom,
     panelCountTill: addSolarSimpleTariff.panelCountTill,
     price: addSolarSimpleTariff.price,
    });
    addTariffSolarSimpleModalVisible.value = false;
    clearSolarSimpleTariff();
   }
  };

  const handleAddSubProduct = async () => {
   if (addOrShowSubProduct.value === 'ADD') {
    const data = addSubProduct;
    data.productID = params.id;
    await dispatch('createSolarSubProduct', data);
    addSubProductModalVisible.value = false;
    clearSubProduct();
    solarSimpleSubProductRef.value.refresh();
    return;
   }
   if (addOrShowSubProduct.value === 'EDIT') {
    await dispatch('patchSolarSubProduct', {
     id: addSubProduct.id,
     active: addSubProduct.active,
     productID: addSubProduct.productID,
     description: addSubProduct.description,
     name: addSubProduct.name,
    });
    addSubProductModalVisible.value = false;
    clearSubProduct();
    solarSimpleSubProductRef.value.refresh();
    return;
   }
  };

  const updateTariff = async () => {
   await dispatch('patchTariff', {
    id: addTariff.id,
    active: addTariff.active,
    productID: addTariff.productID,
    validFrom: addTariff.validFrom,
    validTill: addTariff.validTill,
   });
   addTariffModalVisible.value = false;
   clearTariff();
  };

  const updateSubProduct = async () => {
   await dispatch('patchSolarSubProduct', {
    id: addSubProduct.id,
    active: addSubProduct.active,
    productID: addSubProduct.productID,
    description: addSubProduct.description,
    imageFile: addSubProduct.imageFile,
    name: addSubProduct.name,
   });
   addSubProductModalVisible.value = false;
   clearSubProduct();
  };


  const handleShowTariffEdit = async (tariffs) => {
   addOrShowTariff.value = 'EDIT';
   addTariff.id = tariffs.id;
   addTariff.productID = params.id;
   addTariff.tariffFixedEnergy = tariffs.tariffFixedEnergy;
   addTariff.tariffFixedGas = tariffs.tariffFixedGas;
   addTariff.tariffGas1 = tariffs.tariffGas1;
   addTariff.tariffGas2 = tariffs.tariffGas2;
   addTariff.tariffHigh = tariffs.tariffHigh;
   addTariff.tariffLow = tariffs.tariffLow;
   addTariff.tariffReturnHigh = tariffs.tariffReturnHigh;
   addTariff.tariffReturnLow = tariffs.tariffReturnLow;
   addTariff.tariffReturnSingle = tariffs.tariffReturnSingle;
   addTariff.tariffSingle = tariffs.tariffSingle;
   addTariff.validFrom = moment(tariffs.validFrom).format('YYYY/MM/DD');
   addTariff.validTill = moment(tariffs.validTill).format('YYYY/MM/DD');
   addTariff.active = tariffs.active;
   addTariff.startDateFrom = tariffs.startDateFrom;
   addTariff.startDateTill = tariffs.startDateTill;
   addTariffModalVisible.value = true;
  };

  const handleShowSolarSimpleTariffEdit = async (tariffs) => {
   addOrShowSolarSimpleTariff.value = 'EDIT';
   addSolarSimpleTariff.id = tariffs.id;
   addSolarSimpleTariff.productID = params.id;
   addSolarSimpleTariff.active = tariffs.active;
   addSolarSimpleTariff.price = tariffs.price;
   addSolarSimpleTariff.validFrom = tariffs.validFrom;
   addSolarSimpleTariff.validTill = tariffs.validTill;
   addSolarSimpleTariff.panelCountFrom = tariffs.panelCountFrom;
   addSolarSimpleTariff.panelCountTill = tariffs.panelCountTill;
   addTariffSolarSimpleModalVisible.value = true;
  };

  const handleShowSubProductEdit = async (product) => {
   addOrShowSubProduct.value = 'EDIT';
   addSubProduct.id = product.id;
   addSubProduct.active = product.active;
   addSubProduct.productID = product.productID;
   addSubProduct.description = product.description;
   addSubProduct.imageFile = product.imageFile;
   addSubProduct.imageFileURL = product.imageFileURL;
   addSubProduct.name = product.name;
   addSubProductModalVisible.value = true;
  };

  const handleDeleteSubProduct = (product) => {
   dispatch('deleteSolarSubProduct', product);
  }

  const handleAddQuestion = () => {
   const question = questionOptions.value.filter(q => q.id === selectedAddProductFlowQuestion.value);
   productFlowQuestions.value.push({
    displayName: question[0].displayName,
    flowQuestion: question[0],
    flowQuestionID: question[0].id,
    isRequired: false
   });
   visibleProductFlowQuestions.value = false;
   selectedAddProductFlowQuestion.value = null;
  };

  const handleUpload = async (info) => {
   if (info.file.status === 'done') {
    await dispatch('getProduct', params.id);
    await dispatch('fileUploadedSuccess');
   } else if (info.file.status === 'error') {
    await dispatch('fileUploadedError');
   }
  };

  const fileUploadHeaders = {
   Authorization: `Bearer ${getItem('access_token')}`
  };

  const showFileUpload = () => {
   showFileUploadRef.value = true;
  };

  const handleFileUploadCancel = () => {
   fileList.value = [];
   showFileUploadRef.value = false;
  };

  const uploading = ref(false);
  const beforeUpload = (file) => {
   fileList.value = [...fileList.value, file];
   return false;
  };

  const handleFileUpload = async () => {
   let formData = new FormData();
   formData.append("file", fileList.value[0]);
   await dispatch('uploadProductFile', {formData: formData, id: params.id});
   await dispatch('getProductFiles', params.id);
   await handleFileUploadCancel();
  }

  const handleRemove = (file) => {
   const index = fileList.value.indexOf(file);
   const newFileList = fileList.value.slice();
   newFileList.splice(index, 1);
   fileList.value = newFileList;
  };

  const handleTabClickEnergy = (tab) => {
   switch (tab) {
    case 2:
     dispatch('getTariffs', {id: params.id, page: 1, pageSize: 10});
     break;
    case 3:
     dispatch('getQuestions', {pageSize: 1000});
     dispatch('getProductFlowQuestions', params.id);
     break;
    case 4:
     dispatch('getProductFiles', params.id);
     break;
    default:
     break;
   }
  }

  const handleTabClickShop = (tab) => {
   switch (tab) {
    case 2:
     dispatch('fetchShopSubProducts', {id: params.id, page: 1, pageSize: 100})
     break;
    case 3:
     dispatch('getQuestions', {pageSize: 1000});
     dispatch('getProductFlowQuestions', params.id);
     break;
    case 4:
     dispatch('getProductFiles', params.id);
     break;
    default:
     break;
   }
  }

  const handleTabHydromx = (tab) => {
   switch (tab) {
    case 2:
     dispatch('getProductFiles', params.id);
     break;
    default:
     break;
   }
  }

  const handleTabSolar = (tab) => {
   switch (tab) {
    case 2:
     dispatch('getSolarSubProducts', {productID: params.id, page: 1, pageSize: 20});
     break;
    case 3:
     dispatch('getQuestions', {pageSize: 1000});
     dispatch('getProductFlowQuestions', params.id);
     break;
    case 4:
     dispatch('getProductFiles', params.id);
     break;
    default:
     break;
   }
  }

  const handleTabSolarSimple = (tab) => {
   switch (tab) {
    case 2:
     dispatch('getSolarSimpleTariffs', {id: params.id, page: 1, pageSize: 10});
     break;
    case 3:
     dispatch('getQuestions', {pageSize: 1000});
     dispatch('getProductFlowQuestions', params.id);
     break;
    case 4:
     dispatch('getProductFiles', params.id);
     break;
    default:
     break;
   }
  }

  onMounted(() => {
   dispatch('fetchAvailableProductTypes');
   dispatch('getProductCommunicationsFilters');
   dispatch('getSuppliers');
   dispatch('getSenders');
   dispatch('getScripts', {pageSize: 1000});
   dispatch('getProduct', params.id);
  });

  return {
   form,
   moment,
   errorProduct,
   loadingProduct,
   UpdateProduct1,
   UpdateProduct2,
   UpdateProduct3,
   UpdateProduct4,
   UpdateProduct5,
   UpdateProduct6,
   UpdateProduct7,
   UpdateProduct9,
   UpdateProduct10,
   UpdateProduct11,
   UpdateProduct12,
   activateProduct,
   UpdateCompensation,
   suppliers,
   bagLimitationOptions,
   energyTypeOptions,
   productTypeOptions,
   retentionTypeOptions,
   energyUsageLimitationTypeOptions,
   productExpiryTypeOptions,
   customerTypeOptions,
   defaultOptions,
   offerEmailTemplateOptions,
   confirmationEmailTemplateOptions,
   pdfTemplateOptions,
   handleUpload,
   fileUploadHeaders,
   handleAddCustomerApprovals,
   handleDeleteCustomerApprovals,
   loadingPatchProduct,
   selectedUpdateButton,
   productCommunicationsFilters,
   productFlowQuestions,
   loadingFlowQuestions,
   questionOptions,
   handleDeleteProductFlowQuestions,
   handleAddProductFlowQuestions,
   visibleProductFlowQuestions,
   handleProductFlowQuestionsModalCancel,
   handleAddQuestion,
   selectedAddProductFlowQuestion,
   handleAddTariffModalVisible,
   handleAddTariffModalCancel,
   addTariffModalVisible,
   handleAddTariff,
   addTariff,
   handleShowTariffEdit,
   ProductEnergyUsageLimitationType,
   cleanLimitations,
   contractDurationType,
   scripts,
   showFileUpload,
   handleFileUploadCancel,
   showFileUploadRef,
   fileList,
   uploading,
   beforeUpload,
   handleFileUpload,
   handleRemove,
   addOrShowTariff,
   updateTariff,
   activeKey: ref(1),
   senders,
   syncTariff,
   loadingSyncTariffForProduct,
   productTypes,
   addSolarSimpleTariff,
   handleAddTariffSolarSimpleModalCancel,
   addTariffSolarSimpleModalVisible,
   handleShowSolarSimpleTariffEdit,
   handleAddTariffSolarSimpleModalVisible,
   handleAddSolarSimpleTariff,
   addOrShowSolarSimpleTariff,
   integerValidator,
   handleAddSubProductModalVisible,
   handleAddSubProductModalCancel,
   handleAddSubProduct,
   updateSubProduct,
   addSubProductModalVisible,
   addSubProduct,
   addOrShowSubProduct,
   handleShowSubProductEdit,
   handleDeleteSubProduct,
   solarSimpleSubProductRef,
   handleTabClickShop,
   syncShopProducts,
   handleTabSolar,
   handleTabClickEnergy,
   handleTabSolarSimple,
   handleTabHydromx,
  };
 },
});
</script>
<style lang="scss">
.ck-editor .ck.ck-editor__main > .ck-editor__editable {
 min-height: 150px;
 max-height: 150px;
}
</style>
