<template>
 <sdDrawer
  ref="ShopSubProductAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Product aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" layout="vertical" @finish="createShopSubProduct">
    <a-row :gutter="16">
     <a-col :sm="12" :xs="24" class="mb-25">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24"
            class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: amountValidator}]"
                   label="Bedrag"
                   name="amount">
       <a-input v-model:value="form.amount" placeholder="" type="number"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Categorie"
                   name="subCategoryID">
       <a-select v-model:value="form.subCategoryID" :allowClear="true"
                 class="sDash_fullwidth-select" size="large">
        <a-select-option v-for="(item,index) in productSubCategories" :key="index"
                         :value="item.id" name="subCategoryID">
         {{ item.name }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :loading="loading" class="btn-signin" html-type="submit" type="primary"> Opslaan</a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {FormValidationWrap} from "@/view/styled";
import {amountValidator} from "@/utility/validators";
import {useRoute} from "vue-router";

const AddShopSubProduct = defineComponent({
 name: 'AddShopSubProduct',
 components: {
  FormValidationWrap,
 },
 data() {
  return {
   loading: false,
  }
 },
 setup() {
  const ShopSubProductAddRef = ref();
  const formRef = ref();
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const productsSubProducts = computed(() => state.shop.productsSubProducts);
  const productSubCategories = computed(() => state.shop.productSubCategories);
  const form = reactive({
   shopId: null,
   name: null,
   amount: null,
   subCategoryID: null,
   active: true,
   hasAutoSync: false,
  });

  const closeDrawer = () => {
   ShopSubProductAddRef.value.onClose();
   form.name = null;
   form.amount = null;
   form.active = false;
   form.hasAutoSync = false;
   form.formsubCategoryID = null,
    formRef.value.resetFields();
   dispatch('getShopSubProducts')
   dispatch('fetchShopSubProducts', {id: params.id, page: productsSubProducts.value.currentPage})
  }

  const createShopSubProduct = () => {
   form.shopId = params.id;
   dispatch('createShopSubProducts', {value: form, close: closeDrawer});
  };

  onMounted(() => {
   dispatch('getShopSubCategories', params.id);
  });

  return {
   form,
   createShopSubProduct,
   ShopSubProductAddRef,
   formRef,
   amountValidator,
   productSubCategories,
  };
 },
});

export default AddShopSubProduct;
</script>
