<template>
 <sdDrawer
  ref="SolarSubProductAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Product aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" layout="vertical" @finish="createSolarSubProduct">
    <a-row :gutter="16">
     <a-col :sm="12" :xs="24" class="mb-25">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :sm="12"
            :xs="24" class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: amountValidator}]"
                   label="Prijs"
                   name="price">
       <a-input v-model:value="form.price" name="price" placeholder="" type="number"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                   name="type">
       <a-select v-model:value="form.type" :allowClear="true"
                 class="sDash_fullwidth-select" size="large">
        <a-select-option v-for="(item,index) in solarProductTypeOptions" :key="index"
                         :value="item.value" name="type">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col v-if="form.type && form.type === 'PANEL'"
            :sm="12"
            :xs="24" class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: integerValidator}]"
                   label="Wattpiek"
                   name="wattPeak">
       <a-input v-model:value="form.wattPeak" name="wattPeak" placeholder="" type="text"/>
      </a-form-item>
     </a-col>
     <a-col v-else-if="form.type && form.type !== 'PANEL' && form.type !== 'ADDON' " :sm="12"
            :xs="24" class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: integerValidator}]"
                   label="Maximale Wattpiek"
                   name="maxWattPeak">
       <a-input v-model:value="form.maxWattPeak" name="maxWattPeak" placeholder="" type="text"/>
      </a-form-item>
     </a-col>
     <a-col v-if="form.type && form.type !== 'ADDON'" :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Daktype" name="roofType">
       <a-select v-model:value="form.roofType" :allowClear="true" class="sDash_fullwidth-select"
                 maxTagCount="responsive"
                 size="large">
        <a-select-option v-for="(item,index) in roofTypeOptions" :key="index"
                         :value="item.value"
                         name="roofType">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Min panelen"
                   name="minPanelCount">
       <a-input v-model:value="form.minPanelCount"
                :addon-after="!form.minPanelCount || form.minPanelCount === '0' || form.minPanelCount === '1'  ? 'dag' : 'dagen'"
                onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                placeholder=""
                type="number"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Max panelen"
                   name="maxPanelCount">
       <a-input v-model:value="form.maxPanelCount"
                :addon-after="!form.maxPanelCount || form.maxPanelCount === '0' || form.maxPanelCount === '1'  ? 'dag' : 'dagen'"
                onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                placeholder=""
                type="number"/>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :loading="loadingSolarSubProduct" class="btn-signin" html-type="submit" type="primary"> Aanmaken
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {useStore} from "vuex";
import {FormValidationWrap} from "@/view/styled";
import {amountValidator, integerValidator} from "@/utility/validators";
import {useRoute} from "vue-router";
import {roofTypeOptions, solarProductTypeOptions} from "@/utility/enums";

const AddSolarSubProduct = defineComponent({
 name: 'AddSolarSubProduct',
 components: {
  FormValidationWrap,
 },
 setup() {
  const SolarSubProductAddRef = ref();
  const formRef = ref();
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const loadingSolarSubProduct = computed(() => state.solarSubProduct.loadingSolarSubProduct);
  const currentPage = computed(() => state.solarSubProduct.currentPage);
  const form = reactive({
   productID: '',
   name: null,
   price: null,
   maxWattPeak: null,
   wattPeak: null,
   type: false,
   hasAutoSync: false,
   minPanelCount: null,
   maxPanelCount: null,
   active: true,
  });

  const closeDrawer = () => {
   SolarSubProductAddRef.value.onClose();
   form.name = null;
   form.amount = null;
   form.active = false;
   form.hasAutoSync = false;
   form.formsubCategoryID = null;
   formRef.value.resetFields();
   dispatch('getSolarSubProducts', {productID: params.id, page: currentPage.value})
  }

  const createSolarSubProduct = () => {
   form.productID = params.id;
   form.minPanelCount = parseInt(form.minPanelCount);
   form.maxPanelCount = parseInt(form.maxPanelCount);
   dispatch('createSolarSubProductDrawer', {value: form, close: closeDrawer});
  };

  return {
   form,
   createSolarSubProduct,
   SolarSubProductAddRef,
   formRef,
   amountValidator,
   loadingSolarSubProduct,
   solarProductTypeOptions,
   roofTypeOptions,
   integerValidator,
  };
 },
});

export default AddSolarSubProduct;
</script>
