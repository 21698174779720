<template>
 <PdfViewer ref="pdfViewer"></PdfViewer>
 <TableStyleWrapper2>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="filesTableColumns"
    :dataSource="filesTableData"
    :loading="loadingFiles"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
   />
  </TableWrapper>
 </TableStyleWrapper2>
</template>
<script>
import {TableStyleWrapper2, TableWrapper} from '../../styled';
import {computed, defineComponent, ref} from 'vue';
import {useStore} from "vuex";
import PdfViewer from "../../../components/pdfViewer/PdfViewer";
import {useRoute} from "vue-router";

const filesTableColumns = [
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Acties',
  dataIndex: 'action',
  key: 'action',
 },
];
const FilesListTable = defineComponent({
 name: 'FilesListTable',
 components: {TableStyleWrapper2, TableWrapper, PdfViewer},
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const files = computed(() => state.product.files);
  const loadingFiles = computed(() => state.product.loadingOrderFiles);
  const count = computed(() => state.product.filesCount);
  const currentPage = computed(() => state.product.filesCurrentPage);
  const pdfViewer = ref();
  const showPdf = (data) => {
   pdfViewer.value.showPdf(data, false);
  };
  const handleDelete = async (id) => {
   await dispatch('deleteProductFile', {fileID: id, productID: params.id});
   await dispatch('getProductFiles', params.id);
  };
  const filesTableData = computed(() =>
   files.value.map((files) => {
    const {id, name, fileURL} = files;

    return {
     key: id,
     name: (
      <div class="user-info">
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {name}
        </sdHeading>
       </figcaption>
      </div>
     ),
     action: (
      <div class="table-actions" class="btn-icon">
       <a href={fileURL} target="_blank">
        <sdFeatherIcons type="download" size="16"/>
       </a>
       {name.includes('.pdf') ? (
        <sdButton onClick={() => showPdf({fileName: name, fileURL: fileURL}, false)} className="btn-icon" to="#"
                  type="default" shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       ) : ('')}
       <a-popconfirm
        title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
        ok-text="Ja"
        cancel-text="Nee"
        placement="leftTop"
        onConfirm={() => handleDelete(id)}
       >
        <sdButton className="btn-icon" type="default" to="#" shape="circle">
         <sdFeatherIcons type="trash-2" size="16"/>
        </sdButton>
       </a-popconfirm>
      </div>
     ),
    };
   }),
  );

  return {filesTableColumns, filesTableData, loadingFiles, count, pdfViewer, currentPage};
 },
});

export default FilesListTable;
</script>
