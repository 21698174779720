<template>
 <div>
  <EditShopSubProduct ref="editShopSubProductRef"/>
  <TableStyleWrapper>
   <TableWrapper class="table-responsive">
    <a-table
     :columns="productTableColumns"
     :dataSource="productTableData"
     :loading="loadingFetchShopSubProducts"
     :pagination="{
          defaultPageSize: 100,
          total: productsSubProducts.total,
          current: productsSubProducts.currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${productsSubProducts.total} items`,
        }"
     @change="paginationChange"
    />
   </TableWrapper>
  </TableStyleWrapper>
 </div>
</template>
<script>
import {TableStyleWrapper} from '../style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import EditShopSubProduct from "@/view/product/component/EditShopSubProduct";

const productTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Bedrag',
  dataIndex: 'amount',
  key: 'amount',
 },
 {
  title: 'Status',
  dataIndex: 'active',
  key: 'active',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const ShopSubProductsTable = defineComponent({
 name: 'ShopSubProductsTable',
 components: {TableStyleWrapper, TableWrapper, EditShopSubProduct},
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const editShopSubProductRef = ref();
  const loadingFetchShopSubProducts = computed(() => state.shop.loadingFetchShopSubProducts);
  const productsSubProducts = computed(() => state.shop.productsSubProducts);
  const productTableData = computed(() => {
    return productsSubProducts.value.data.map((product) => {
     const {id, name, imageFileURL, amount, active} = product;

     return {
      key: id,
      id: id,
      name: (
       <div class="user-info">
        <figure>
         {imageFileURL && imageFileURL !== '' ? (
          <img style={{width: '40px'}} src={`${imageFileURL}`} alt=""/>
         ) : (
          <img style={{width: '40px'}} src={require('@/static/img/users/1.png')} alt=""/>
         )}
        </figure>
        <figcaption>
         <sdHeading class="user-name" as="h6">
          {name}
         </sdHeading>
        </figcaption>
       </div>
      ),
      amount: <span>{new Intl.NumberFormat('nl-nl', {style: 'currency', currency: 'EUR'}).format(amount)}</span>,
      active: (<span className="status-text" style={{
       color: 'white',
       backgroundColor: active ? '#0CAB7C' : '#E30D0F'
      }}>{active ? 'Actief' : 'Inactief'}</span>),
      action: (
       <div className="table-actions">
        <sdButton onClick={() => editShopSubProductRef.value.showDrawer(id)} className="btn-icon" to="#" type="default"
                  shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       </div>
      ),
     };
    })
   }
  );

  const paginationChange = async (event) => {
   await dispatch('setShopSubProductsCurrentPage', event.current);
   await dispatch('fetchShopSubProducts', {id: params.id, page: event.current, pageSize: 100});
  }

  return {
   productTableColumns,
   productTableData,
   loadingFetchShopSubProducts,
   paginationChange,
   state,
   productsSubProducts,
   editShopSubProductRef,
  };
 },
});

export default ShopSubProductsTable;
</script>
