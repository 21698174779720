<template>
 <sdDrawer
  ref="solarSubProductEditRef"
  :form="form"
  :showOpenButton="false"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Product configuratie"
  type="submit"
 >
  <FormValidationWrap>
   <a-form v-if="form" ref="formRef" :model="form" layout="vertical" @finish="updateShopSubProduct">
    <a-row :gutter="16">
     <a-col :xs="24">
      <Upload>
       <figure class="photo-upload align-center-v">
        <img
         v-if="form.imageFileURL && form.imageFileURL !== ''"
         :src="form.imageFileURL"
         alt=""
         style="max-width: 120px"
        />
        <img
         v-else
         :src="require('@/static/img/avatar/profileImage.png')"
         alt=""
         style="max-width: 120px"
        />
        <figcaption>
         <a-upload
          :action="`${fileUrl}products/${params.id}/solarsubproducts/${form.id}/file`"
          :disabled="!$can('update', 'user')"
          :headers="fileUploadHeaders"
          :multiple="false"
          name="file"
          @change="handleUpload">
          <a class="btn-upload" to="#">
           <sdFeatherIcons size="16" type="camera"/>
          </a>
         </a-upload>
        </figcaption>
       </figure>
      </Upload>
     </a-col>
     <a-col :sm="12" :xs="24" class="mb-25">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24" class="mb-25">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Actief"
                   name="active">
       <a-select v-model:value="form.active" :allowClear="true" class="sDash_fullwidth-select" name="active"
                 size="large">
        <a-select-option v-for="(item,index) in defaultOptions" :key="index" :value="item.value"
                         name="active">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24" class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: amountValidator}]"
                   label="Prijs"
                   name="price">
       <a-input v-model:value="form.price" name="price" placeholder="" type="text"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type"
                   name="type">
       <a-select v-model:value="form.type" :allowClear="true"
                 class="sDash_fullwidth-select" size="large">
        <a-select-option v-for="(item,index) in solarProductTypeOptions" :key="index"
                         :value="item.value" name="type">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col v-if="form.type && form.type === 'PANEL' && form.type === 'ADDON'" :sm="12" :xs="24" class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: integerValidator}]"
                   label="Wattpiek"
                   name="wattPeak">
       <a-input v-model:value="form.wattPeak" name="wattPeak" placeholder="" type="text"/>
      </a-form-item>
     </a-col>
     <a-col v-else-if="form.type && form.type !== 'PANEL' && form.type !== 'ADDON'"
            :rules="{required: true,message: 'Dit veld is verplicht'}"
            :sm="12"
            :xs="24" class="mb-25">
      <a-form-item :rules="[{required: true,message: 'Dit veld is verplicht'},{validator: integerValidator}]"
                   label="Maximale Wattpiek"
                   name="maxWattPeak">
       <a-input v-model:value="form.maxWattPeak" name="maxWattPeak" placeholder="" type="number"/>
      </a-form-item>
     </a-col>
     <a-col v-if="form.type && form.type !== 'ADDON'" :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Daktype" name="roofType">
       <a-select v-model:value="form.roofType" :allowClear="true" class="sDash_fullwidth-select"
                 maxTagCount="responsive"
                 size="large">
        <a-select-option v-for="(item,index) in roofTypeOptions" :key="index"
                         :value="item.value"
                         name="roofType">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Min panelen"
                   name="minPanelCount">
       <a-input v-model:value="form.minPanelCount"
                :addon-after="!form.minPanelCount || form.minPanelCount === '0' || form.minPanelCount === '1'  ? 'dag' : 'dagen'"
                onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                placeholder=""
                type="number"/>
      </a-form-item>
     </a-col>
     <a-col :sm="12" :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Max panelen"
                   name="maxPanelCount">
       <a-input v-model:value="form.maxPanelCount"
                :addon-after="!form.maxPanelCount || form.maxPanelCount === '0' || form.maxPanelCount === '1'  ? 'dag' : 'dagen'"
                onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                placeholder=""
                type="number"/>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :loading="loadingPatchSolarSubProduct" class="btn-signin" html-type="submit" type="primary"> Opslaan
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, ref} from 'vue';
import {useStore} from "vuex";
import {FormValidationWrap, Upload} from "@/view/styled";
import {useRoute} from "vue-router";
import {getItem} from "@/utility/localStorageControl";
import {getApiURL} from "@/config/dataService/dataService";
import {defaultOptions, roofTypeOptions, solarProductTypeOptions,} from "../../../utility/enums";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {amountValidator, integerValidator} from "@/utility/validators";

const EditShopSubProduct = defineComponent({
 name: 'EditShopSubProduct',
 components: {
  FormValidationWrap,
  Upload,
 },
 data() {
  return {
   loading: false,
   fileUrl: getApiURL(),
   editor: ClassicEditor,
   editorConfig: {
    height: 200,
   },
  }
 },
 setup() {
  const solarSubProductEditRef = ref();
  const formRef = ref();
  const {params} = useRoute();
  const {state, dispatch} = useStore();
  const loadingPatchSolarSubProduct = computed(() => state.solarSubProduct.loadingPatchSolarSubProduct);
  const form = computed(() => {
   return state.solarSubProduct.solarSubProduct
  });
  const currentPage = computed(() => state.solarSubProduct.currentPage);

  const closeDrawer = () => {
   solarSubProductEditRef.value.onClose();
   formRef.value.resetFields();
   dispatch('resetSolarSubProduct');
   dispatch('getSolarSubProducts', {productID: params.id, page: currentPage.value, pageSize: 20});
  }

  const showDrawer = async (id) => {
   await dispatch('getSolarSubProduct', {productID: params.id, id: id});
   await solarSubProductEditRef.value.showDrawer();
  }
  const updateShopSubProduct = () => {
   form.value.productID = params.id;
   form.value.minPanelCount = parseInt(form.value.minPanelCount);
   form.value.maxPanelCount = parseInt(form.value.maxPanelCount);
   dispatch('patchSolarSubProductDrawer', {value: form.value, close: closeDrawer});
  };

  const fileUploadHeaders = {
   Authorization: `Bearer ${getItem('access_token')}`
  };

  const handleUpload = async (info) => {
   if (info.file.status === 'done') {
    await dispatch('getSolarSubProduct', {productID: params.id, id: form.value.id});
    await dispatch('fileUploadedSuccess');
   } else if (info.file.status === 'error') {
    await dispatch('fileUploadedError');
   }
  };

  return {
   form,
   updateShopSubProduct,
   solarSubProductEditRef,
   formRef,
   showDrawer,
   fileUploadHeaders,
   handleUpload,
   defaultOptions,
   loadingPatchSolarSubProduct,
   amountValidator,
   integerValidator,
   params,
   solarProductTypeOptions,
   roofTypeOptions,
  };
 },
});

export default EditShopSubProduct;
</script>
