<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="tariffTableColumns"
    :dataSource="tariffTableData"
    :loading="loadingTariffs"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          showTotal: (total,range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute} from "vue-router";

const tariffTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Prijs',
  dataIndex: 'price',
  key: 'price',
 },
 {
  title: 'Aantal panelen',
  dataIndex: 'panelCountFrom',
  key: 'panelCountFrom',
 },
 {
  title: 'Status',
  dataIndex: 'active',
  key: 'active',
 },
 {
  title: 'Geldig van',
  dataIndex: 'validFrom',
  key: 'validFrom',
 },
 {
  title: 'Geldig tot',
  dataIndex: 'validTill',
  key: 'validTill',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const SolarSimpleTariffTable = defineComponent({
 name: 'SolarSimpleTariffTable',
 components: {TableStyleWrapper, TableWrapper},
 emits: ['showSolarSimpleTariffEdit'],
 setup(props, {emit}) {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const tariffs = computed(() => state.solarSimpleTariff.tariffs);
  const count = computed(() => state.solarSimpleTariff.count);
  const loadingTariffs = computed(() => state.solarSimpleTariff.loadingTariffs);
  const handleEdit = (item) => {
   emit('showSolarSimpleTariffEdit', item);
  };
  const resolveStatusVariant = active => {
   if (active) return {variant: 'active', label: 'Actief'}
   if (!active) return {variant: 'inactive', label: 'Inactief'}
  }
  const tariffTableData = computed(() =>
   tariffs.value.map((tariff) => {
    const {
     id,
     validFrom,
     validTill,
     active,
     price,
     panelCountFrom,
     panelCountTill,
    } = tariff;

    return {
     key: id,
     id: id,
     price: <span><small>{price}</small></span>,
     panelCountFrom: <span><small>{panelCountFrom} t/m {panelCountTill}</small></span>,
     active: <span
      className={`status-text ${resolveStatusVariant(active).variant}`}>{resolveStatusVariant(active).label}</span>,
     validFrom: <span><small>{moment(validFrom).format('DD-MM-YYYY')}</small></span>,
     validTill: <span><small>{moment(validTill).format('DD-MM-YYYY')}</small></span>,
     action: (
      <div class="table-actions">
       <sdButton onClick={() => handleEdit(tariff)} class="btn-icon" to="#" type="default" shape="circle">
        <sdFeatherIcons type="edit" size="16"/>
       </sdButton>
      </div>
     ),
    };
   }),
  );

  const paginationChange = async (event) => {
   await dispatch('getSolarSimpleTariffs', {id: params.id, page: event.current, pageSize: 3});
  }

  return {tariffTableColumns, tariffTableData, loadingTariffs, count, paginationChange};
 },
});
export default SolarSimpleTariffTable;
</script>
