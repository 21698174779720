<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="tariffTableColumns"
    :dataSource="tariffTableData"
    :loading="loadingTariffs"
    :pagination="{
          defaultPageSize: 10,
          total: count,
          showTotal: (total,range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute} from "vue-router";

const tariffTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Elektriciteit',
  dataIndex: 'electricity',
  key: 'electricity',
 },
 {
  title: 'Teruglevering',
  dataIndex: 'hasReturn',
  key: 'hasReturn',
 },
 {
  title: 'Gas',
  dataIndex: 'gas',
  key: 'gas',
 },
 {
  title: 'Status',
  dataIndex: 'active',
  key: 'active',
 },
 {
  title: 'Geldig van',
  dataIndex: 'validFrom',
  key: 'validFrom',
 },
 {
  title: 'Geldig tot',
  dataIndex: 'validTill',
  key: 'validTill',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const TariffListTable = defineComponent({
 name: 'TariffListTable',
 components: {TableStyleWrapper, TableWrapper},
 emits: ['showTariffEdit'],
 setup(props, {emit}) {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const tariffs = computed(() => state.tariff.tariffs);
  const count = computed(() => state.tariff.count);
  const loadingTariffs = computed(() => state.tariff.loadingTariffs);
  const handleEdit = (item) => {
   emit('showTariffEdit', item);
  };
  const resolveStatusVariant = active => {
   if (active) return {variant: 'active', label: 'Actief'}
   if (!active) return {variant: 'inactive', label: 'Inactief'}
  }
  const tariffTableData = computed(() =>
   tariffs.value.map((tariff) => {
    const {
     id,
     validFrom,
     validTill,
     active,
     tariffFixedGas,
     tariffFixedEnergy,
     tariffGas1,
     tariffGas2,
     tariffReturnHigh,
     tariffReturnLow,
     tariffReturnSingle,
     tariffSingle,
     tariffHigh,
     tariffLow,
     tariffFixedReturn
    } = tariff;

    return {
     key: id,
     id: id,
     electricity: <div>
      <span><small>Enkel:{tariffSingle ? tariffSingle : '0.00'}</small></span><span><small>Normaal:{tariffHigh ? tariffHigh : '0.00'}</small></span><span><small>Dal:{tariffLow ? tariffLow : '0.00'}</small></span>
      <span><small>Vaste leveringskosten: {tariffFixedEnergy ? tariffFixedEnergy : '0.00'}</small></span>
      {tariffFixedReturn ? `<span><small>Vaste terugleveringskosten: ${tariffFixedReturn}</small></span>` : ''}

     </div>,
     hasReturn: <div>
      <span><small>Enkel:{tariffReturnSingle ? tariffReturnSingle : '0.00'}</small></span><span><small>Normaal:{tariffReturnHigh ? tariffReturnHigh : '0.00'}</small></span><span><small>Dal:{tariffReturnLow ? tariffReturnLow : '0.00'}</small></span>
     </div>,
     gas: <div><span><small>Leveringstarief (G1): {tariffGas1 ? tariffGas1 : '0.00'}</small></span><span><small>Leveringstarief (G2): {tariffGas2 ? tariffGas2 : '0.00'}</small></span><span><small>Vaste leveringskosten: {tariffFixedGas ? tariffFixedGas : '0.00'}</small></span>
     </div>,
     active: <span
      className={`status-text ${resolveStatusVariant(active).variant}`}>{resolveStatusVariant(active).label}</span>,
     validFrom: <span><small>{moment(validFrom).format('DD-MM-YYYY')}</small></span>,
     validTill: <span><small>{moment(validTill).format('DD-MM-YYYY')}</small></span>,
     action: (
      <div class="table-actions">
       <sdButton onClick={() => handleEdit(tariff)} class="btn-icon" to="#" type="default" shape="circle">
        <sdFeatherIcons type="edit" size="16"/>
       </sdButton>
      </div>
     ),
    };
   }),
  );

  const paginationChange = async (event) => {
   await dispatch('getTariffs', {id: params.id, page: event.current, pageSize: 3});
  }


  return {tariffTableColumns, tariffTableData, loadingTariffs, count, paginationChange};
 },
});

export default TariffListTable;
</script>
