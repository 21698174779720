<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="solarSubProductTableColumns"
    :dataSource="solarSubProductTableData"
    :loading="loadingSolarSubProducts"
    :pagination="{
          defaultPageSize: 5,
          total: count,
          showTotal: (total,range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent, onMounted} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useAbility} from "@casl/vue";

const solarSubProductTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: '',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Omschrijving',
  dataIndex: 'description',
  key: 'description',
 },

 {
  title: 'Status',
  dataIndex: 'active',
  key: 'active',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const SolarSimpleSubProduct = defineComponent({
 name: 'SolarSimpleSubProduct',
 components: {TableStyleWrapper, TableWrapper},
 emits: ['edit', 'delete'],
 setup(props, {emit}) {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const {can} = useAbility();
  const products = computed(() => state.solarSubProduct.solarSubProducts);
  const count = computed(() => state.solarSubProduct.count);
  const loadingSolarSubProducts = computed(() => state.solarSubProduct.loadingSolarSubProducts);
  const handleEdit = (item) => {
   emit('edit', item);
  };
  const handleDelete = (item) => {
   emit('delete', item);
  };
  const resolveStatusVariant = active => {
   if (active) return {variant: 'active', label: 'Actief'}
   if (!active) return {variant: 'inactive', label: 'Inactief'}
  }
  const solarSubProductTableData = computed(() =>
   products.value.map((product) => {
    const {
     id,
     active,
     name,
     description,
     imageFileURL,
    } = product;

    return {
     key: id,
     id: id,
     name: (
      <div class="user-info">
       <figure>
        {imageFileURL && imageFileURL !== '' ? (
         <img style={{width: '40px'}} src={`${imageFileURL}`} alt=""/>
        ) : (
         <img style={{width: '40px'}} src={require('@/static/img/users/1.png')} alt=""/>
        )}
       </figure>
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {name}
        </sdHeading>
       </figcaption>
      </div>
     ),
     description: <span class="small">{description}</span>,
     active: <span
      className={`status-text ${resolveStatusVariant(active).variant}`}>{resolveStatusVariant(active).label}</span>,
     action: (
      <div class="table-actions">
       <sdButton onClick={() => handleEdit(product)} class="btn-icon" to="#" type="default" shape="circle">
        <sdFeatherIcons type="edit" size="16"/>
       </sdButton>
       {can('delete', 'product') ? (
        <a-popconfirm
         title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
         ok-text="Ja"
         cancel-text="Nee"
         placement="leftTop"
         onConfirm={() => handleDelete(product)}
        >
         <sdButton className="btn-icon" type="default" to="#" shape="circle">
          <sdFeatherIcons type="trash-2" size="16"/>
         </sdButton>
        </a-popconfirm>
       ) : ('')}
      </div>
     ),
    };
   }),
  );

  const paginationChange = async (event) => {
   await dispatch('getSolarSubProducts', {productID: params.id, page: event.current, pageSize: 5});
  }

  const refresh = async () => {
   await dispatch('getSolarSubProducts', {productID: params.id, page: 1, pageSize: 5});
  }

  onMounted(() => {
   dispatch('getSolarSubProducts', {productID: params.id, page: 1, pageSize: 5});
  });

  return {
   solarSubProductTableColumns,
   solarSubProductTableData,
   loadingSolarSubProducts,
   count,
   paginationChange,
   handleDelete,
   refresh
  };
 },
});

export default SolarSimpleSubProduct;
</script>
